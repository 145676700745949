.my-goals__onboarding {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.my-goals__onboarding__tip-1 {
  position: absolute;
  top: 20px;
  background-color: #fff;
  border-radius: 25px 0 0 25px;
  color: #000;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
  z-index: 100;
  display: flex;

  padding: 20px;
  right: 120px;
  width: calc(85% - 75px);

  &:after {
    content: '';
    background-color: #fff;
    position: absolute;
    transform: rotate(45deg);

    width: 45px;
    height: 45px;
    right: -22px;
    top: 9px;
  }

  .title {
    margin-right: 10px;
    @include font-size(20);

    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .description {
    font-family: $font__avenir;
    @include font-size(18);

    display: flex;
    align-items: center;
  }
}

.my-goals__onboarding__tip-2 {
  position: absolute;
  bottom: 20px;
  width: 95%;
  margin: 0 2.5%;
  background-color: #fff;
  border-radius: 25px 0;
  color: #000;
  padding: 20px;
  z-index: 100;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
  display: flex;

  &:before {
    content: '';
    background-color: #fff;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    top: -7px;
  }

  .title {
    margin-right: 10px;
    @include font-size(20);

    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .description {
    font-family: $font__avenir;
    @include font-size(18);

    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
  }
}
