.home .manifesto__container {
  @media only screen and (min-width: $screen-md-min) {
    min-height: 400px;
  }
}

.manifesto__container {
  background-color: #000;
}

.manifesto__grid--large {
  @media only screen and (min-width: $screen-md-min) {
    position: relative;
  }

  .swiper-container {
    @media only screen and (min-width: $screen-md-min) {
      max-width: 700px;
      margin: auto;
      position: static;
    }
  }

  // Swiper pagination
  .swiper-pagination {
    @media only screen and (min-width: $screen-md-min) {
      text-align: right;
    }
  }
}
