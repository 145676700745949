html.webp {
  .challenge-item__supervision::after {
    background-image: url('../images/challenges/supervision_triangle.webp');
  }
}

// challenge item styling
.challenge-item__wrapper,
.challenge-item {
  height: 100%;
}

.challenge-item {
  border-radius: 25px 0 25px 0;
  background-color: #fff;
  padding-bottom: 0;
  cursor: pointer;

  position: relative;

  &--show {
    padding-bottom: 185px;

    .challenge-item__compacted {
      height: auto;
      visibility: visible;

      // transition: 0.5s transform ease-in-out, 0.5s opacity ease-in-out 0.5s;
    }

    .challenge-item__footer {
      visibility: visible;

      // transition: 0.5s visibility ease-in-out 0.5s;
    }

    .challenge-item__mobile-arrow svg {
      transform: rotate(180deg);
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    padding-bottom: 185px;
    cursor: auto;
  }
}

.challenge-item__header {
  border-radius: 25px 0 25px 0;
  padding: 8px 15px;

  display: flex;
  align-items: center;

  h2 {
    font-weight: bold;
    font-family: $font__avenir;

    @include font-size(20);
  }
}

.challenge-item__title {
  margin-right: 15px;
}

.challenge-item__icon {
  margin-right: 15px;
  display: flex;

  svg {
    width: 40px;
    height: 40px;
  }
}

.challenge-item__mobile-arrow {
  display: block;
  margin-left: auto;
  flex-basis: 30px;
  flex-shrink: 0;

  @media only screen and (min-width: $screen-md-min) {
    display: none;
  }

  svg {
    transition: 0.3s transform ease-in-out;
    transform: rotate(0deg);

    .cls-1 {
      fill: #fff;
    }
  }
}

.challenge-item__compacted {
  height: 0;
  visibility: hidden;

  @media only screen and (min-width: $screen-md-min) {
    height: auto;
    visibility: visible;
  }
}

.challenge-item__footer {
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 100%;
  z-index: 1;

  visibility: hidden;

  // transition: 0.5s visibility ease-in-out;

  @media only screen and (min-width: $screen-md-min) {
    visibility: visible;
  }

  &--open-options {
    z-index: 100;
  }
}

.challenge-item__image {
  border-radius: 25px 0 25px 0;
  overflow: hidden;
  margin-bottom: 20px;

  position: relative;
  padding-top: 56%;

  picture {
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
  }
}

.challenge-item__supervision {
  background-color: rgba(46, 166, 224, 0.75);
  border-bottom-right-radius: 25px;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 37px;
  padding: 12px 20px 12px 35px;
  z-index: 10;

  font-weight: bold;
  font-family: $font__avenir;
  @include font-size(18);

  &::after {
    content: '';
    background-image: url('#{$image_path}/challenges/supervision_triangle.png');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 65px;
    height: 60px;
    position: absolute;
    left: -35px;
    top: -10px;
  }
}

.challenge-item__super-challenge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.challenge-item__details {
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: $font__avenir;

  .description {
    &--hide {
      height: 55px;
      overflow: hidden;
    }
    &--show {
      height: auto;
      overflow: visible;
    }
  }

  .read-more {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: auto;
    margin-top: 7px;
    text-decoration: underline;
    font-weight: bold;
  }
}

.challenge-item__details .download-button {
  color: $color_feature;
  background-color: #fff;
  text-transform: uppercase;
  padding: 5px 18px;
  border-radius: 25px 0 25px 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  margin: 20px auto 0;

  transition: 0.3s color ease-in-out, 0.3s background-color ease-in-out;

  font-family: $font__lubalinBold;
  @include font-size(26);

  svg {
    max-width: 22px;
    margin-left: 10px;
    max-height: 28px;

    .cls-2 {
      transition: 0.3s fill ease-in-out;
    }
  }
}

html.no-touchevents .challenge-item__details .download-button {
  &:hover {
    color: #fff;
    background-color: $color_feature;

    svg .cls-2 {
      fill: #fff;
    }
  }
}

.challenge-item__body {
  padding: 20px;
  padding-bottom: 80px;
  position: relative;
}

.challenge-item__confirmation {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  margin: {
    top: 0;
    right: 15px;
    bottom: 20px;
    left: 15px;
  }
}

.challenge-item__done {
  cursor: pointer;
  border-radius: 25px 0 0 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  @include font-size(20);

  &--completed {
    border-radius: 25px 0;
  }

  .status {
    flex-grow: 2;
  }

  .tickbox {
    background-color: transparent;
    border: 0;
    width: 50px;
    height: 37px;
    margin-right: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    position: relative;

    svg {
      // margin: 0 auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--ticked svg {
      width: 75%;
    }

    &--unticked svg {
      max-width: 28px;
    }

    .challenge-cross {
      width: 32px;
    }
  }
}

.challenge-item__confirmation {
  border-radius: 25px 0 25px 0;
}

.challenge-item__social {
  background-color: #e7e7e7;
  border-radius: 25px 0 25px 0;
  text-align: center;
  display: flex;

  // Share & Like Icon
  div {
    color: #000;
    display: flex;
    align-items: center;
  }
  .share,
  .like {
    cursor: pointer;
    background-color: transparent;
    border: none;
    flex-basis: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    text-transform: uppercase;

    @include font-size(17);

    margin: 0 25px;
    padding: 10px 0;
    border-radius: 25px 0;

    svg {
      transition: 0.3s transform ease-in-out;
      width: 30px;
      margin-right: 15px;
      height: 32px;
    }
  }

  .like--liked {
    color: #fff;

    svg {
      transform: rotate(-25deg);
      .cls-1 {
        fill: #fff;
      }
    }
  }
}

// challenge item background colours by type
.challenge-item--food {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $food_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $food_type;
  }
}

.challenge-item--creative {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $creative_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $creative_type;
  }
}

.challenge-item--recycle {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $recycle_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $recycle_type;
  }
}

.challenge-item--fauna {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $fauna_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $fauna_type;
  }
}

.challenge-item--water {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $water_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $water_type;
  }
}

.challenge-item--schools {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $schools_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $schools_type;
  }
}

.challenge-item--energy {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $energy_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__social .cls-1 {
    fill: $energy_type;
  }
}

.challenge-item--flora {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $flora_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $flora_type;
  }
}

.challenge-item--travel {
  .challenge-item__header,
  .challenge-item__done,
  .challenge-item__social .like--liked {
    background-color: $travel_type;
  }

  .challenge-item__done .cls-4,
  .challenge-item__social .cls-1,
  .challenge-item__social .challenge-downloads-icon .cls-2,
  .challenge-item__done .challenge-box-cross .cls-1 {
    fill: $travel_type;
  }
}
