.content-grid {
  width: 100%;

  &--one-item {
    .grid-row > div:first-child {
      @media only screen and (min-width: $screen-md-min) {
        margin: 0 calc(33.33% / 2);
        //margin: 0 calc(math.div(33.33%, 2));
      }
    }
  }

  .grid-row {
    @media (min-width: $screen-md-min) {
      margin-bottom: 15px;

      & > div:first-child {
        padding-right: 15px;
      }

      & > div:last-child {
        padding-left: 15px;
      }
    }
  }

  // Grid Row First
  .grid-row__1x2 {
    .content-item__wrapper {
      margin-bottom: 15px;

      @media (min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }

    .grid-col-2 {
      @extend .flex;
      @extend .flex-wrap;
      @extend .justify-content-between;

      @media (min-width: $screen-md-min) {
        display: block;

        .content-item__wrapper {
          width: auto;

          &:first-child,
          &:last-child {
            padding-left: 0;
            padding-right: 0;
          }

          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  // Grid Row Second
  .grid-row__3x {
    .content-item__wrapper {
      margin-bottom: 15px;

      @media (min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }

    & > div:nth-child(2) {
      @media (min-width: $screen-md-min) {
        padding-left: 12.5px;
        padding-right: 12.5px;
      }
    }

    & > div:last-child {
      @media (min-width: $screen-md-min) {
        padding-left: 15px;
      }
    }
  }

  // Grid Row Fourth
  .grid-row__2x1 {
    .content-item__wrapper {
      margin-bottom: 15px;

      @media (min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }

    .grid-col-1 {
      @extend .flex;
      @extend .flex-wrap;
      @extend .justify-content-between;

      @media (min-width: $screen-md-min) {
        display: block;

        .content-item__wrapper {
          width: auto;

          &:first-child,
          &:last-child {
            padding-left: 0;
            padding-right: 0;
          }
          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  // Grid Row Fourth
  .grid-row__1xmpu {
    .content-item__wrapper {
      @media (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
        margin-top: 20px;
      }
    }

    .mpu-square {
      margin-bottom: 15px;

      @media (min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }
  }
}

.content-grid__related-title {
  color: #000;
  text-transform: uppercase;
  @include font-size(22);

  @media (min-width: $screen-md-min) {
    @include font-size(32);
  }
}
