$font__avenir: 'avenir-rounded', sans-serif;
$font__avenir--demi: 'avenir-rounded-demi', sans-serif;
$font__avenir--bold: 'avenir-rounded-bold', sans-serif;
$font__avenir--med: 'avenir-med', sans-serif;

$font__lubalinBold: 'Lubalin Graph ITC Turner Latin', sans-serif;

$font__lubalinBoldArabic: 'Lubalin Graph ITCTurnerAraW03B', sans-serif;

// Default Font Size
// value repersented in pixels
$default-text-size: 16;

// Heading Font Sizes
$font-size-h1: '50px';
$font-size-h2: '40px';
$font-size-h3: '30px';

// Headings Repsonsive Reductions
// Setup some sort of rem-calc mixin??? could be useful
