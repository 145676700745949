.video-playlists__container {
  background: rgb(250, 188, 44);
  background: -moz-linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  background: linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fabc2c",endColorstr="#feeb34",GradientType=1);

  padding: 15px 0;

  h1 {
    color: #000000;
    margin: 0;
    padding: 0;
  }
}

.videopage {
  margin-top: 80px;
  min-height: 350px;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: 50px;
  }
  h1 {
    text-align: center;
  }
}
