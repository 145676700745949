.are-you-teacher {
  background: rgb(229, 1, 126);
  background: -moz-linear-gradient(90deg, rgba(229, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(229, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  background: linear-gradient(90deg, rgba(229, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5017e",endColorstr="#971a81",GradientType=1);

  padding-top: 40px;
  padding-bottom: 40px;

  text-align: center;
}

.are-you-teacher__button {
  font-family: $font__lubalinBold;
  position: relative;
  text-transform: uppercase;
  background-color: #fff;
  display: block;
  cursor: pointer;
  text-align: left;
  border: none;
  min-width: 160px;
  max-width: 400px;
  margin: 0 auto 36px;
  padding: 10px 10px;
  padding-left: 45px;
  border-radius: 25px 0 25px 0;
  @include font-size(16);
  width: 100%;

  @media only screen and (min-width: $screen-sm-min) {
    @include font-size(20);
    min-height: 66px;
    padding-left: 60px;
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
    min-height: 74px;
    padding-left: 60px;
  }

  a {
    color: #000;
    text-align: left;
  }

  svg {
    position: absolute;
    height: 50px;
    width: 50px;
    left: -12px;
    top: -4px;

    @media only screen and (min-width: $screen-sm-min) {
      height: 70px;
      left: -21px;
      top: -1px;
      width: 70px;
    }

    @media only screen and (min-width: $screen-md-min) {
      height: 80px;
      width: 80px;
      left: -30px;
      top: -3px;
    }
  }
}

.are-you-teacher__title {
  @include font-size(24);
  margin-bottom: 40px;
  text-transform: uppercase;

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(30);
  }
}

.are-you-teacher__body {
  margin-bottom: 20px;
  font-family: $font__avenir;

  a {
    text-decoration: underline;
  }
}

.are-you-teacher__grid {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: $screen-sm-min) {
    flex-direction: row;
  }
}

.are-you-teacher__buttons {
  margin-right: 30px;
  margin-left: 15px;
}
.are-you-teacher__logos {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media only screen and (min-width: $screen-sm-min) {
    display: block;
    max-width: 200px;

    margin-right: 15px;
    margin-left: 30px;
  }

  img {
    max-width: 150px;

    @media only screen and (min-width: $screen-sm-min) {
      flex-direction: column;
      max-width: 100%;
    }

    &:first-child {
      margin-bottom: 30px;
    }
  }
}
