// hide the bitmovin player watermark
.bmpui-ui-watermark {
  display: none;
}

#bitmovin-player__mobile-game {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
}
