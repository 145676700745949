//Sub page backgrounds
.sub-page--quizzes {
  .sub-page__title {
    margin-bottom: 0;
  }
  .sub-page__header {
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 0;
    background: rgb(0, 85, 165);
    background: -moz-linear-gradient(90deg, rgba(0, 85, 165, 1) 0%, rgba(0, 156, 225, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 85, 165, 1) 0%, rgba(0, 156, 225, 1) 100%);
    background: linear-gradient(90deg, rgba(0, 85, 165, 1) 0%, rgba(0, 156, 225, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0055a5",endColorstr="#009ce1",GradientType=1);
  }

  .sub-page__quiz {
    background-color: #fff;
    margin-bottom: 40px;
  }
}

.sub-page iframe {
  position: relative;
  z-index: 1;
}

.sub-page--rewards {
  .sub-page__header {
    padding-top: 20px;
    margin-bottom: 0;

    @media (min-width: $screen-md-min) {
      padding-top: 40px;
    }
  }

  .sub-page__media-container {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
  }

  .sub-page__description {
    padding: {
      top: 30px;
      right: 25px;
      bottom: 25px;
      left: 25px;
    }
    margin: {
      top: -10px;
      right: auto;
      bottom: 20px;
      left: auto;
    }

    position: relative;
    width: 100%;

    @media (min-width: $screen-md-min) {
      padding: {
        top: 160px;
        right: 45px;
        bottom: 45px;
        left: 45px;
      }
      margin-top: -149px;
    }

    p {
      @include font-size(21);
    }
  }
}

.sub-page__header {
  padding-top: 20px;
  margin-bottom: 20px;

  @media (min-width: $screen-md-min) {
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.sub-page__media-container {
  position: relative;
  z-index: 1;
  text-align: center;
}

.sub-page__title {
  position: relative;
  color: #fff;
  letter-spacing: 2px;

  line-height: 1;
  margin: 20px auto 0;
  margin-bottom: 20px;

  text-align: center;
  width: 65%;
  font-family: $font__avenir--demi;

  @include font-size(24);

  @media (min-width: $screen-sm-min) {
    width: 50%;
  }

  @media (min-width: $screen-md-min) {
    // margin-top: 20px;
    @include font-size(48);

    width: 70%;
    margin-top: 0;
  }

  @media (min-width: $screen-lg-min) {
    width: 65%;
  }
}

.sub-page__description {
  font-family: $font__avenir--demi;

  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: {
    left-radius: 25px;
    right-radius: 25px;
  }
  padding: {
    top: 260px;
    right: 25px;
    bottom: 25px;
    left: 25px;
  }
  margin: {
    top: -240px;
    right: auto;
    bottom: 20px;
    left: auto;
  }

  position: relative;
  width: 100%;

  @media (min-width: $screen-md-min) {
    padding: {
      top: 200px;
      right: 45px;
      bottom: 45px;
      left: 45px;
    }
    margin-top: -200px;
  }

  &--no-margin {
    margin-top: -10px;
    padding-top: 35px;

    @media (min-width: $screen-md-min) {
      padding-top: 45px;
      margin-top: -40px;
    }
  }

  p {
    font-family: $font__avenir;
  }
}

.sub-page--downloads .sub-page__description {
  padding-top: 380px;
  margin-top: -360px;

  @media (min-width: $screen-md-min) {
    padding-top: 360px;
  }
}

.sub-page__title--apps {
  padding-top: 30px;

  @media (min-width: $screen-md-min) {
    padding-top: 60px;
  }

  .bar {
    background-color: #000000;
    h1 {
      text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);
      @include font-size(19);
      line-height: 1;
      text-align: center;

      @media (min-width: $screen-sm-min) {
        @include font-size(40);
      }
    }
  }

  .bar-content {
    position: relative;

    @media (min-width: $screen-md-min) {
      margin-top: 0;
    }

    &__title {
      text-align: center;
      padding-right: 90px;
      width: 75%;
      margin-left: 25%;

      @media (min-width: $screen-md-min) {
        padding-right: 0;
        margin: 0 auto;
        width: 60%;
      }

      @media (min-width: $screen-lg-min) {
        padding-right: 0;
        width: 70%;
      }
    }

    .bar-content__icon {
      position: absolute;
      right: 15px;
      max-width: 80px;

      @media (min-width: $screen-sm-min) {
        max-width: 141px;
      }

      @media (min-width: $screen-md-min) {
        max-width: 141px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.sub-page__emojis {
  @media (min-width: $screen-md-min) {
    padding: {
      top: 18px;
      bottom: 5px;
    }
  }

  .emoji-item__section {
    padding: 15px 0px 0;

    @media (min-width: $screen-md-min) {
      padding: 0;
    }
  }

  // Override the width of the container
  &.container {
    width: 100%;
    max-width: 1060px;
  }
}

.sub-page__emojis__container {
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;

  @media (min-width: $screen-md-min) {
    margin-bottom: 40px;
  }
}

.sub-page__tagged-content__container {
  background-color: #000;
  padding: {
    top: 15px;
    bottom: 15px;
  }
  margin-bottom: 10px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 40px;
  }
}
