html.is-ie .challenges-counter__count {
  min-width: 150px;
}

.stats-cta {
  background-color: $color_feature;
  padding: 10px 30px;
  border-radius: 50px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 20px;

  @media only screen and (min-width: $screen-md-min) {
    max-width: 500px;
  }

  opacity: 1;

  transition: 0.6s opacity ease-in-out;

  &--hidden {
    opacity: 0;
  }
}

.challenges-counter {
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1;
}

.challenges-counter__text {
  text-align: center;
  text-transform: uppercase;
  max-width: 100%;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    max-width: 300px;
    @include font-size(18);
  }
}

.challenges-counter--text {
  a {
    height: 100%;
    display: flex;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .challenges-counter__icon {
    transform-origin: center;
    transform: rotate(270deg);
    margin-left: auto;
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    .challenge-arrow-down {
      height: 35px;
      .cls-1 {
        fill: #fff;
      }
    }
  }

  .challenges-counter__text {
    width: 100%;
    max-width: 100%;
    @include font-size(18);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(20);
      max-width: 300px;
    }
  }
}

.challenges-counter__count {
  min-width: fit-content;
  margin-left: 10px;
  @include font-size(30);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(40);
  }
}
