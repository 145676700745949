.challenge-item--compacted-view {
  position: relative;
  height: 100%;

  img {
    width: 100%;
  }
}

.challenge-item__compacted-title {
  position: absolute;
  bottom: 0;
  border-radius: 25px 0;
  padding: 8px 15px;
  width: 100%;
  display: flex;
  align-items: center;

  h2 {
    font-weight: bold;
    font-family: $font__avenir;

    @include font-size(20);
  }
}

// challenge item background colours by type
.challenge-item--food {
  .challenge-item__compacted-title {
    background-color: $food_type;
  }
}

.challenge-item--recycle {
  .challenge-item__compacted-title {
    background-color: $recycle_type;
  }
}

.challenge-item--fauna {
  .challenge-item__compacted-title {
    background-color: $fauna_type;
  }
}

.challenge-item--water {
  .challenge-item__compacted-title {
    background-color: $water_type;
  }
}

.challenge-item--schools {
  .challenge-item__compacted-title {
    background-color: $schools_type;
  }
}

.challenge-item--energy {
  .challenge-item__compacted-title {
    background-color: $energy_type;
  }
}

.challenge-item--flora {
  .challenge-item__compacted-title {
    background-color: $flora_type;
  }
}

.challenge-item--travel {
  .challenge-item__compacted-title {
    background-color: $travel_type;
  }
}

.challenge-item--creative {
  .challenge-item__compacted-title {
    background-color: $creative_type;
  }
}
