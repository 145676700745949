* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  line-height: 1.2;
  scroll-behavior: smooth;

  &.webp {
    .bg-wrapper:before {
      background-image: url('../images/background/bg-icon-repeat.webp');
    }

    #brim-main .expand-button {
      &__shrink {
        background-image: url('../images/my-goals/shrink_my_goals.webp');
      }
      &__expand {
        background-image: url('../images/my-goals/expand_my_goals.webp');
      }
    }
  }
}

// Google Recapcaha
.grecaptcha-badge {
  z-index: 9999999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Disable outline for no-touchevents only
// Shouldn't be doin this anyway for Accessiblity
html.no-touchevents {
  a,
  button,
  input {
    &:focus {
      outline: none;
    }
  }
}

.bg-wrapper {
  background: rgb(26, 159, 229);
  background: -moz-linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  background: linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a9fe5",endColorstr="#2cfbfc",GradientType=1);
  position: relative;
  background-size: 100% 100%;

  &:before {
    background-image: url('#{$image_path}/background/bg-icon-repeat.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.5;
  }
}

body {
  color: #fff;
  font-family: $font__lubalinBold, sans-serif;
  @include font-size(16);

  // @media only screen and (min-width: $screen-lg-min) {
  //   min-height: 768px;
  // }

  &.show-menu {
    header {
      width: 100%;
      position: fixed;
      z-index: 999999;
      display: block !important;
    }

    #brim-main .expand-button {
      top: 135px;
    }
  }

  &.fullscreen-mode {
    .grecaptcha-badge {
      visibility: hidden;
    }
  }
}

#brim-main .expand-button {
  width: 45px;
  height: 45px;
  position: absolute;
  left: 15px;
  top: 15px;

  background-color: $color_feature;
  border: 4px #fff solid;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  padding: 10px;
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;

  background-position: center;
  background-size: 56%;
  background-repeat: no-repeat;

  &__shrink {
    background-image: url('#{$image_path}/my-goals/shrink_my_goals.jpg');
  }
  &__expand {
    background-image: url('#{$image_path}/my-goals/expand_my_goals.jpg');
  }
}

picture {
  line-height: 0;
}

.site-exit-button {
  cursor: pointer;
}

.fullscreen-trigger {
  background-color: #000;
  text-align: center;
  @include font-size(20);

  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  &.ios {
    .fullscreen-trigger__container--ios {
      display: block;
    }
    .fullscreen-trigger__container--non-ios {
      display: none;
    }
  }
  &.non-ios {
    .fullscreen-trigger__container--non-ios {
      display: block;
    }
    .fullscreen-trigger__container--ios {
      display: none;
    }
  }
}

.fullscreen-trigger__container {
  position: absolute;
  left: calc(50% - 125px);
  top: 100px;

  height: 250px;
  width: 250px;

  img {
    width: 100px;
  }
}

.fullscreen-trigger__text {
  text-align: center;
  display: block;
  margin-top: 10px;
}

main,
.main {
  height: 100%;
  position: relative;
}

.generic-background {
  min-height: 90vh;
  padding-bottom: 0;
  position: relative;

  @media (min-width: $screen-md-min) {
    padding-bottom: 30px;
  }

  &--no-bg {
    background-image: none;
  }
}

.background--underlay {
  background-repeat: no-repeat;
  background-size: 180%;
  background-position: top center;

  position: absolute;
  width: 100%;
  padding-top: 70%;

  &__tint {
    display: none;
    top: 0;
    height: 100%;
    position: absolute;
    width: 100%;

    @supports (mix-blend-mode: color) {
      display: block;
      mix-blend-mode: color;
    }
  }

  &.underlay-only {
    background-size: auto 130%;
    padding-top: auto;
    height: 100%;
  }

  &--badgestint {
    display: none;
    @supports (mix-blend-mode: color) {
      display: block;
      background-color: #00bbb4;
      mix-blend-mode: color;
    }

    position: absolute;

    height: 100%;
    width: 100%;
  }
}

#brim-mask {
  z-index: 999999;
}

#app {
  &.fullscreen,
  #brim-main {
    height: 100%;
  }
}

svg {
  display: block;
  width: 100%;
  height: auto;
}

img {
  max-width: 100%;
}

a {
  color: #fff;
  text-decoration: none;
}

.clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}
