.rotate-screen__container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 0;
}

.rotate-screen {
  width: 100%;
  max-width: 475px;
}

.rotate-screen__image {
  padding: 20px 40px;
}

.rotate-screen__text {
  color: #000;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.2;
  @include font-size(35);
}
