.cn-content {
  margin-bottom: 40px;
}

.cn-content__blurb {
  @include font-size(14);
}

.cn-content__logo {
  display: none;
  margin-left: 10px;
  margin-bottom: 10px;
  float: right;
  width: 90px;

  @media (min-width: $screen-md-min) {
    display: block;
  }
}
