.filter-options__container {
  background-color: #000;
  padding: 20px;
  margin-bottom: 40px;
}

.filter-options {
  text-align: center;
}

.filter-options button {
  color: #000;

  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  padding: 10px 20px;

  text-transform: uppercase;
  @include font-size(16);
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
  margin: 5px;

  @media (min-width: $screen-md-min) {
    @include font-size(18);
    padding: 15px 25px;
  }

  @media (min-width: $screen-lg-min) {
    @include font-size(24);
    padding: 18px 40px;
  }

  &.selected {
    background-color: $color_feature;
  }
}
