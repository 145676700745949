.media-container__image {
  margin-bottom: 20px;

  &--rounded {
    img {
      border-radius: 25px;
    }
  }
}

.media-container__video {
  margin-bottom: 20px;

  &--rounded {
    video {
      border-radius: 25px;
    }
  }
}

.media-container__store--buttons {
  margin: 20px auto 0;
  text-align: center;

  .store-button {
    padding: 0px 10px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.media-container__cta {
  text-align: center;
}

.media-container__cta button {
  color: #fff;
  cursor: pointer;
  background-color: #000000;
  border: none;
  padding: 8px 15px;
  margin: 3px;
  margin-bottom: 50px;
  border-radius: 45px;
  @include font-size(18);
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);

  @media (min-width: $screen-md-min) {
    padding: 15px 30px;
    @include font-size(24);
  }
}

#iframe-container {
  height: 100%;
  &.hidden {
    display: none;
  }
}
#bitmovin-container {
  width: 100%;
}
