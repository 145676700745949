// Media Query sizes
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1300px;

// Setup colours for across the site
$color_feature: #ec0080;
$color_links: $color_feature; //:#ff6553;
$color_earth_bg: #90495f;
$color_space_bg: #10164e;
$color_default_bg: #111344;

// https://sass-lang.com/documentation/modules/math
@use "sass:math";

// Helpful mixins

// Designs tend to be in points value
// We convert points into pixels then into rem
// formula : pixels = math.div(points, .75)
// General rule : 1pt = 1.33333px

@function pointsToPixels($value) {
  @return math.div($value, 0.75);
}

// *** getting tired of working out the font sizes from the designs

// Font size mixin - Usage:
// @include font-size(32);
// or if passing AI design points value
// @include font-size(48, true);
// params:
// --- $sizeValue = $default-text-size (see scss/site-variables/_typography.scss for font size variable)
// --- $pointsValue = false
$default-text-size: 16;

@mixin font-size($sizeValue: $default-text-size, $pointsValue: false) {
  @if ($pointsValue) {
    font-size: pointsToPixels($sizeValue) + px;
    font-size: math.div(pointsToPixels($sizeValue), $default-text-size) + rem;
  } @else {
    font-size: $sizeValue + px;
    font-size: math.div($sizeValue, $default-text-size) + rem;
  }
}

@import 'critical/challenges-counter';
@import 'critical/marque';
@import 'critical/navigation';
@import 'critical/site-logo';
@import 'critical/cookie-bar';
