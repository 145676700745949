html.webp .modal--level-up {
  background-image: url('../images/my-goals/new_plant_textless.webp');
}

.modal__container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  z-index: 9000;
}

.modal {
  max-width: 500px;
  background-color: #fff;
  border-radius: 25px;
  color: #000;
  width: 90%;
  text-align: center;
  box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.35);
  position: fixed;
  padding: 25px;
  top: 10%;

  @media (min-width: $screen-sm-min) {
    width: 60%;
    top: 20%;
  }

  @media (min-width: $screen-lg-min) {
    width: 40%;
    top: 30%;
  }
}

.modal__header {
  margin-bottom: 60px;
}

.modal__header__title {
  @include font-size(28);
}

.modal__buttons {
  width: 100%;
}

.modal__buttons__continue,
.modal__buttons__exit {
  color: #fff;
  background-color: #000;
  display: inline-block;
  border: none;
  padding: 10px 20px;
  margin: 3px;
  border-radius: 25px;
  @include font-size(24);

  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
}

.modal__buttons__exit {
  color: #000;
  background-color: $color_links;
}

// Level up
.modal--level-up {
  background-image: url('#{$image_path}/my-goals/new_plant_textless.png');
  background-repeat: no-repeat;
  width: 370px;
  height: 280px;
  background-size: 100% 100%;

  position: fixed;
  bottom: 0;

  left: 50%;
  transform: translate(-50%) scale(0.8);
  transform-origin: bottom center;

  z-index: 99999;

  transition: 0.3s bottom ease-in-out;

  @media only screen and (min-width: 400px) {
    transform: translate(-50%) scale(1);
  }

  &--hide {
    bottom: -300px;
  }
}

.modal--level-up__text {
  position: absolute;
  bottom: 36px;
  height: 180px;
  width: 200px;
  right: 50px;
  text-align: center;

  span {
    display: block;
    padding: 0 5px 10px;
  }

  a {
    display: flex;
    position: absolute;
    bottom: 3px;
    height: 42px;
    width: 100%;
    justify-content: center;
    align-items: center;

    color: $color_feature;
    @include font-size(20);
  }
}

.modal--level-up__button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_feature;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);

  button {
    font-family: $font__avenir--bold;
    border: none;
    background: none;
    cursor: pointer;
    color: #fff;

    @include font-size(26);
  }
}

.modal--exit-site {
  .modal__container {
    background-color: rgba(0, 0, 0, 0.35);
  }
  .modal {
    width: 90%;
    padding: 35px;
    background-color: #000;
    color: #fff;
    border-radius: 25px 0;

    @media only screen and (min-width: $screen-md-min) {
      width: 60%;
    }
  }
  .modal__buttons {
    display: flex;
    justify-content: space-between;

    flex-direction: column;

    @media only screen and (min-width: $screen-md-min) {
      flex-direction: row;
    }

    a {
      display: inline-block;
    }
  }

  .modal__header {
    margin-bottom: 10px;
  }
  .modal__countdown,
  .modal__header__title {
    margin-bottom: 40px;
  }
  .modal__header__title {
    @include font-size(35);
    text-transform: uppercase;
  }
  .modal__header__description,
  .modal__countdown {
    font-family: $font__avenir;
    @include font-size(18);
  }
  .modal__buttons__continue {
    width: 100%;
  }
  .modal__buttons__continue,
  .modal__buttons__exit {
    color: #fff;
    background-color: $color_feature;
    padding: 10px 20px;
    border-radius: 25px 0;
  }
}

.modal--legal-info {
  background-color: rgba(0, 0, 0, 0.9);
  .modal {
    width: 90%;
    top: 10px;
    background-color: #fff;
    color: #2c0e33;
  }
  .modal__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2c0e33;
    background-color: #ff6553;

    border-radius: 25px;
    width: 55px;
    height: 45px;

    button {
      border: none;
      background: none;
      cursor: pointer;
      color: #2c0e33;

      @include font-size(20);

      @media (min-width: $screen-md-min) {
        @include font-size(26);
      }
    }
  }
  .modal__scrollarea {
    height: calc(100% - 40px);
    margin-top: 40px;
  }
  .modal__header__title {
    font-family: $font__lubalinBold;
    @include font-size(38);

    @media (min-width: $screen-md-min) {
      @include font-size(48);
    }
  }
  .modal__description {
    text-align: left;
    font-family: $font__avenir;

    h1,
    h2,
    h3,
    strong {
      @include font-size(22);

      @media (min-width: $screen-md-min) {
        @include font-size(28);
      }
    }

    p {
      margin-bottom: 15px;

      @include font-size(20);

      @media (min-width: $screen-md-min) {
        @include font-size(24);
      }
    }

    a {
      color: #2c0e33;
      &:visited {
        color: #2c0e39;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.modal--offline-message {
  .modal {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none;

    top: 50%;
    transform: translateY(-50%);
  }

  .page-not-found__container {
    padding: 0;
    flex-direction: column;
    align-items: center;

    .page-not-found {
      width: 100%;
      max-width: none;
    }
  }

  .page-not-found__link--container {
    background-color: $color_feature;
    border-radius: 25px;
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
    margin-top: 20px;
    width: min-content;

    button {
      display: flex;
      align-self: center;
      justify-content: center;
      padding: 10px 25px;
      font-family: $font__avenir--bold;
      border: none;
      background: none;
      cursor: pointer;
      color: #fff;
      font-size: 26px;
      font-size: 1.625rem;

      @include font-size(20);

      @media (min-width: $screen-md-min) {
        @include font-size(26);
      }

      span {
        margin: 4px;
      }
    }
  }
}
