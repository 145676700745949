// Schools Page icons
.schools-teacher-icon {
  .cls-1 {
    fill: #fff;
  }
}

.schools-quiz-icon {
  .cls-1 {
    fill: #fff;
  }
}

.schools-contact-icon {
  .cls-1 {
    fill: #fff;
  }
}

// Content type SVG icons
.resource-icon {
  .cls-2 {
    fill: #cbcbf2;
  }
}
.download-icon,
.app-icon,
.game-icon,
.downloads-icon,
.quiz-icon,
.story-icon,
.video-icon {
  .cls-1 {
    opacity: 0.2;
  }
  .cls-2 {
    fill: #fff;
  }
  .icon-shadow {
    opacity: 0.25;
  }
}
.external-icon {
  .st0 {
    opacity: 0.2;
  }
  .st1 {
    fill: #ffffff;
  }
}

.check-mark {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 12px;
}

.close-sign {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 7.56px;
}

.plus-sign {
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: $travel_type;
  }
  .cls-3 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 7.56px;
  }
}

// Generic
.generic-arrow-down {
  .cls-1 {
    fill: #fff;
  }
}

// Nav SVG icons
.nav-app-icon,
.nav-game-icon,
.nav-story-icon,
.nav-downloads-icon,
.nav-quiz-icon,
.nav-other-icon,
.nav-product-icon,
.nav-campaign-icon,
.nav-video-icon,
.nav-Lang-toggle-icon,
.nav-my-goals-icon,
.nav-global-goals-icon,
.nav-map-icon {
  .cls-1 {
    opacity: 0.2;
  }
  .cls-2 {
    fill: #fff;
  }
}
.nav-schools-icon {
  .cls-1 {
    fill: #fff;
  }
}

.nav-login-icon {
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: $travel_type;
  }
}

.nav-challenge-icon {
  .cls-2 {
    stroke: #fff;
  }
}

// Emoji Icon
.emoji-more-iocn {
  .cls-1 {
    opacity: 0.2;
  }
  .cls-2,
  .cls-5,
  .cls-7,
  .cls-9 {
    fill: #fff;
  }
  .cls-3 {
    fill: url(#radial-gradient);
  }
  .cls-4,
  .cls-5 {
    font-size: 14.85px;
  }
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    font-family: LubalinGraphITCTurner-Bold, Lubalin Graph ITC Turner;
    font-weight: 700;
  }
  .cls-6,
  .cls-7 {
    font-size: 10.93px;
  }
  .cls-8,
  .cls-9 {
    font-size: 8.97px;
  }
  .cls-10 {
    fill: #000104;
  }
  .cls-11 {
    fill: #eb1c2d;
  }
  .cls-12 {
    fill: #252729;
  }
  .cls-13 {
    fill: #96c93d;
  }
  .cls-14 {
    fill: #9d1c30;
  }
  .cls-15 {
    fill: #fcf7d0;
  }
  .cls-16 {
    fill: #435464;
  }
  .cls-17 {
    fill: #c32032;
  }
  .cls-18 {
    opacity: 0.1;
  }
  .cls-19 {
    fill: #f4be98;
  }
  .cls-20 {
    fill: #e89d6c;
  }
  .cls-21 {
    fill: #512c1d;
  }
  .cls-22 {
    fill: #86422b;
  }
  .cls-23 {
    fill: #000105;
  }
  .cls-24 {
    fill: url(#linear-gradient);
  }
  .cls-25 {
    fill: url(#linear-gradient-2);
  }
  .cls-26 {
    fill: url(#linear-gradient-3);
  }
}

// Challenges
.challenge-arrow-down {
  .cls-1 {
    fill: $color_feature;
  }
}
.challenge-box-cross {
  .cls-1 {
    fill: #fff;
  }
}
.challenge-box-ticked {
  .cls-1 {
    opacity: 0.2;
  }
  .cls-2 {
    stroke: #000;
  }
  .cls-3 {
    stroke: #fff;
    fill: #fff;
  }
  .cls-4 {
    fill: $travel_type;
  }
  .cls-5 {
    fill: #fff;
  }
}
.challenge-box-unticked {
  .cls-1 {
    stroke: #000;
  }
  .cls-2 {
    stroke: #fff;
  }
}

// challenge icons
.challenge-water-icon {
  .cls-1 {
    fill: none;
    stroke: #b0f7f2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.7px;
  }
}

.challenge-flora-icon {
  .cls-1 {
    fill: #d5e5a1;
  }
  .cls-2 {
    fill: #fff;
  }
}

.challenge-schools-icon {
  .cls-1 {
    fill: #fddbff;
  }
}

.challenge-fauna-icon {
  .cls-1 {
    fill: #ffbab3;
  }
}
.challenge-travel-icon {
  .cls-1 {
    fill: #f4c6e7;
  }
  .cls-2 {
    fill: $travel_type;
  }
  .cls-4 {
    fill: #fff;
  }
  .cls-5 {
    stroke: $travel_type;
  }
  .cls-6 {
    fill: none;
  }
}

.challenge-recycle-icon {
  .cls-1 {
    fill: #b3ffd2;
  }
  .cls-2 {
    fill: #fff;
  }
  .cls-3 {
    fill: #00b24b;
  }
}

.challenge-energy-icon {
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #fff6d2;
  }
  .cls-3 {
    fill: #ffc100;
  }
  .cls-4 {
    fill: none;
    stroke: #fff6d2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.1px;
  }
}

.challenge-downloads-icon {
  .cls-1 {
    opacity: 0.2;
  }
  .cls-2 {
    fill: $color_feature;
  }
}

.challenge-like-icon {
  .cls-1 {
    fill: #fff;
  }
}

.challenge-food-icon {
  .cls-1 {
    fill: #f2f2f2;
  }
  .cls-2 {
    fill: #bde3ad;
  }
}

.challenge-creative-icon {
  .cls-1 {
    fill: #f2f2f2;
  }
  .cls-2 {
    fill: #cbcbf2;
  }
}

.challenge-share-icon {
  .cls-1 {
    fill: #fff;
  }
}

// My Goals
.my-goals-locked-icon {
  .cls-1 {
    fill: #fff;
  }
}

.my-goals-downloads-icon {
  .cls-1 {
    opacity: 0;
  }
  .cls-2 {
    fill: #fff;
  }
}

.my-goals-arrow-icon {
  .cls-1 {
    stroke: #fff;
  }
}

// map
.map-filter-icon {
  .cls-1,
  .cls-2 {
    fill: #ff6e56;
    stroke-width: 3px;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .cls-2 {
    fill: #ed0080;
    stroke-width: 2px;
  }
}

.map-marker-ripples {
  .cls-1,
  .cls-2 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.45px;
  }
  .cls-1 {
    opacity: 0.2;
  }
  .cls-2 {
    opacity: 0.5;
  }
}

// No Wifi
.no-wifi {
  fill: #fff;
}
