/* Terms of use bar */

#tos-bar {
  height: 69px;
  background-color: #fff000;
  position: relative;
  z-index: 11;
}

#tos-wrap {
  width: 95.7%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

#tos-text p {
  font: 19px/70px AvenirNextRoundedMedium, arial, sans-serif;
  margin-bottom: 0;
  color: #000;
}

#tos-text span.avenir-bold {
  font-weight: bold;
  color: #000;
}

#tos-text p a {
  font: 23px 'TTNormsProExtraBlack', arial, helvetica, sans-serif;
  border-bottom: 3px solid #000;
  margin: 0 8px;
  color: #000;
  text-transform: uppercase;
}

#tos-text p a:hover {
  border-bottom: 6px solid #000;
}

#tos-close {
  border: 2px solid #000;
  padding: 8px 20px;
  /* letter-spacing: 1px; */
}

#tos-close:hover {
  background-color: #000;
  background-position: -77px 2px;
  cursor: pointer;
}

#tos-close span {
  font-family: AvenirNextRoundedMedium, arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #000;
}

#tos-close:hover span {
  color: white;
}

@media only screen and (min-width: 1426px) and (max-width: 1921px) {
  #tos-text p {
    font-size: 24px;
    line-height: 65px;
  }

  #tos-text p a {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1225px) and (max-width: 1425px) {
  #tos-text p {
    font-size: 20px;
    line-height: 65px;
  }

  #tos-text p a {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1087px) and (max-width: 1224px) {
  #tos-text p {
    font-size: 18px;
    line-height: 68px;
  }

  #tos-text p a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1086px) {
  #tos-text p {
    font-size: 16px;
    line-height: 69px;
  }

  #tos-text p a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1086px) {
  #tos-text p {
    font-size: 16px;
    line-height: 69px;
  }

  #tos-text p a {
    font-size: 16px;
  }

  #tos-close span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 980px) {
  #tos-wrap {
    align-items: end;
    height: 100%;
  }

  #tos-bar {
    height: 94px;
  }

  #tos-text {
    width: 90%;
    align-self: center;
  }

  #tos-text p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    align-self: center;
    max-width: 550px;
    margin: 0 auto;
  }

  #tos-text p a {
    font-size: 18px;
  }

  #tos-text p a:hover {
    border-bottom: 4px solid #000;
  }

  #tos-close {
    align-self: center;
    font-size: 14px;
    border: 3px solid #000;
  }

  #tos-close:hover {
    background-position: -74px -38px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 749px) {
  #tos-text {
    width: 85%;
  }
}

@media only screen and (max-width: 639px) {
  #tos-wrap {
    height: 100%;
    justify-content: space-around;
    flex-direction: column;
  }

  #tos-bar {
    height: 155px;
  }

  #tos-text {
    float: none;
  }

  #tos-text p {
    font-size: 16px;
    line-height: 30px;
    width: 303px;
    text-align: center;
    margin: 0 auto;
  }

  #tos-text p a {
    font-size: 19px;
    border-bottom: 2px solid #000;
    margin: 0 4px;
    color: #000;
  }

  #tos-text p a:hover {
    border-bottom: 2px solid #000;
  }

  #tos-close:hover {
    background-position: 90px -90px;
    background-color: #fff000;
  }

  #tos-close p span {
    text-transform: uppercase;
  }
}
