.page-not-found {
  background: rgb(26, 159, 229);
  background: -moz-linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  background: linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a9fe5",endColorstr="#2cfbfc",GradientType=1);
  border-radius: 25px;
  color: #fff;
  position: relative;
  width: 80%;
  max-width: 450px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: $screen-lg-min) {
    width: 60%;
  }
}

.page-not-found__icon {
  width: 100%;
  max-width: 170px;
  margin-bottom: 20px;
}

.page-not-found__container {
  padding-top: 50px;
  margin: 0 auto;
  min-height: 50vh;
  position: relative;

  @media (min-width: $screen-md-min) {
    padding-top: 50px;
  }

  // &:before {
  //   content: '';
  //   background-image: url('#{$image_path}/page-not-found/404.png');
  //   background-size: 100%;
  //   background-repeat: no-repeat;
  //   position: absolute;
  //   z-index: 1;
  //   height: 180px;
  //   width: 190px;
  //   left: calc(50% - 95px);
  //   top: 30px;
  //
  //   @media (min-width: $screen-md-min) {
  //     height: 340px;
  //     width: 330px;
  //     left: calc(50% - 155px);
  //     top: 70px;
  //   }
  // }
}

.page-not-found__title {
  margin-top: 0;
  text-transform: uppercase;
}
