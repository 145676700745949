// Helpful mixins

// Designs tend to be in points value
// We convert points into pixels then into rem
// formula : pixels = math.div(points, .75)
// General rule : 1pt = 1.33333px

@function pointsToPixels($value) {
  @return math.div($value, 0.75);
}

// *** getting tired of working out the font sizes from the designs

// Font size mixin - Usage:
// @include font-size(32);
// or if passing AI design points value
// @include font-size(48, true);
// params:
// --- $sizeValue = $default-text-size (see scss/site-variables/_typography.scss for font size variable)
// --- $pointsValue = false

@mixin font-size($sizeValue: $default-text-size, $pointsValue: false) {
  @if ($pointsValue) {
    font-size: pointsToPixels($sizeValue) + px;
    font-size: math.div(pointsToPixels($sizeValue), $default-text-size) + rem;
  } @else {
    font-size: $sizeValue + px;
    font-size: math.div($sizeValue, $default-text-size) + rem;
  }
}
