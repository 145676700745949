.super-challenge-icon {
  background-image: url('../images/challenges/super-challenge-clean.png');
  width: 99px;
  height: 154px;
  padding-top: 30px;
  text-align: center;
}

.super-challenge-icon__line-1 {
  color: #000;
  @include font-size(20);
}

.super-challenge-icon__line-2 {
  color: #000;

  @include font-size(13);
}
