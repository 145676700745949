.faq-item {
  border-bottom: 1px solid #ffffff;
  padding: 30px 0;

  @media only screen and (min-width: $screen-md-min) {
    padding: 50px;
  }

  &--hide {
    .faq-item__description {
      display: none;
    }

    .faq-item__title {
      margin-bottom: 0;
    }
  }

  &--show {
    .faq-item__title .icon svg {
      transform: rotate(180deg);
    }

    .faq-item__description {
      display: block;
    }
  }
}

.faq-item__title,
.faq-item__description {
  font-family: $font__avenir--demi;
}

.faq-item__title {
  cursor: pointer;
  display: flex;

  margin-bottom: 20px;
}

.faq-item__title .question {
  font-family: $font__avenir--bold;
  @include font-size(22);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(32);
  }
}

.faq-item__title .icon {
  margin-top: 10px;
  margin-left: auto;
  margin-right: 20px;

  max-width: 22px;

  @media only screen and (min-width: $screen-md-min) {
    max-width: 32px;
  }

  svg {
    margin: 0 10px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
    .cls-1 {
      fill: #ffffff;
    }
  }
}

.faq-item__description {
  padding-left: 10px;
  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    padding-left: 50px;

    @include font-size(22);
  }
}
