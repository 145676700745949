html.webp .faq__body__lower-section {
  background-image: url('../images/faq/faq_footer.webp');
}

body.faq footer {
  margin-top: 0;
}

.faq__header__title {
  background: rgb(230, 1, 126);
  background: -moz-linear-gradient(180deg, rgba(230, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(230, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  background: linear-gradient(180deg, rgba(230, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e6017e",endColorstr="#971a81",GradientType=1);

  text-align: center;
  padding: 15px;

  @media only screen and (min-width: $screen-md-min) {
    padding: 30px;
  }

  h1 {
    margin: 0;
    @include font-size(20);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(50);
    }
  }
}

.faq__list {
  list-style: none;
}

.faq__body {
  background: rgb(9, 97, 121);
  background: -moz-linear-gradient(0deg, rgba(9, 97, 121, 1) 0%, rgba(0, 166, 233, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(9, 97, 121, 1) 0%, rgba(0, 166, 233, 1) 100%);
  background: linear-gradient(0deg, rgba(9, 97, 121, 1) 0%, rgba(0, 166, 233, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#096179",endColorstr="#00a6e9",GradientType=1);
}

.faq__body__lower-section {
  padding: 20px 0;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background-image: url('#{$image_path}/faq/faq_footer.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  min-height: 200px;

  @media (min-width: $screen-sm-min) {
    min-height: 250px;
  }

  @media (min-width: $screen-md-min) {
    min-height: 350px;
  }

  @media (min-width: $screen-lg-min) {
    min-height: 400px;
  }

  @media (min-width: $screen-xl-min) {
    min-height: 556px;
  }

  .mpu-leader {
    margin-bottom: 0px;
  }
}
