.turner-info {
  @include font-size(12);
  color: #494949;
  margin-bottom: 30px;
  text-align: center;

  @media (min-width: $screen-md-min) {
    text-align: left;
  }
}

.turner-info__images {
  justify-content: center;
  margin-bottom: 30px;

  @media (min-width: $screen-md-min) {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}

.turner-info__images .footer-logo {
  margin: {
    left: 10px;
    right: 10px;
  }

  @media (min-width: $screen-md-min) {
    margin: {
      left: 0;
      right: 20px;
    }
  }
}
