.challenges__offline-message {
  position: relative;
  border-radius: 25px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);

  &::before {
    content: '';
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    bottom: -10px;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
  }
}

.challenges__offline-message__body {
  background-color: white;
  border-radius: 25px;
  padding: 15px 25px;
  text-align: center;
  color: black;
  position: relative;
}

.challenges__offline-message__body .title {
  @include font-size(25);
  font-family: $font__lubalinBold;
  margin-bottom: 5px;

  @media only screen and (min-width: 500px) {
    @include font-size(30);
  }
}

.challenges__offline-message__body .description_1 {
  @include font-size(16);
  font-family: $font__avenir;
  margin-bottom: 5px;
}

.challenges__offline-message__body .description_2 {
  @include font-size(16);
  font-family: $font__avenir--bold;
}
