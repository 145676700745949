html.mobile.ios .install-prompt::before {
  content: '';
  width: 40px;
  height: 40px;
  background-color: #37f3f4;
  position: absolute;
  left: calc(50% - 20px);
  bottom: -20px;
  transform: rotate(45deg);
  z-index: -1;
}

.install-prompt {
  background: rgb(26, 159, 229);
  background: -moz-linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  background: linear-gradient(180deg, rgba(26, 159, 229, 1) 0%, rgba(44, 251, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a9fe5",endColorstr="#2cfbfc",GradientType=1);
  color: black;
  position: fixed;
  bottom: 40px;
  z-index: 990;
  border-radius: 45px;
  width: 90%;
  margin: 0 5%;
  left: 0;
  display: flex;
  padding: 20px;

  @media (min-width: $screen-md-min) {
    max-width: 450px;
    left: calc(50%);
    transform: translateX(-50%);
    margin: 0;
  }

  &--hidden {
    display: none;
  }
}

.install-prompt__image {
  margin-right: 20px;
  flex: 1;

  img {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    @media (min-width: $screen-md-min) {
      width: 180px;
    }
  }
}

.install-prompt__text {
  flex: 1;

  @media (min-width: $screen-md-min) {
    flex: 2;
  }

  p {
    @include font-size(12);
    font-family: $font__avenir;
    margin-bottom: 10px;

    &.heading {
      @include font-size(16);
      font-family: $font__lubalinBold;
    }
  }
}

.install-prompt__text .ios-share {
  width: 16px;
  margin: 0 3px;
  vertical-align: bottom;
}

.install-prompt__button {
  outline: 0;
  cursor: pointer;
  border: none;
  background-color: $color_feature;
  color: #fff;
  @include font-size(16);
  padding: 5px 10px;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.default-text--hidden,
.ios-text--hidden {
  display: none;
}

.header__pwa-status {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.header__pwa-status__online,
.header__pwa-status__webapp {
  background-color: #41560d;
  margin: 0 10px;
  text-align: center;
  padding: 8px;
  flex: 1;
}
