.header {
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.35);
  background-color: #000;
  z-index: 9;

  min-height: 70px;

  @media (min-width: $screen-lg-min) {
    min-height: auto;
  }
}

.header__upper {
  padding: {
    top: 15px;
    bottom: 15px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 0;
  }
}

.header__cn-logo {
  align-self: center;
  width: 70px;

  position: relative;
  z-index: 101;

  @media (min-width: $screen-lg-min) {
    width: 100px;
    margin: {
      right: 20px;
      left: 10px;
    }
  }
}
