.marque--carousel .stats-cta {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 0;
  transform: translate(-50%, 10px);
  display: none;

  @media only screen and (min-width: $screen-md-min) {
    display: block;
  }
}

.marque--carousel {
  position: relative;
  background-color: #fff;

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }

  padding-top: 54.5%;

  @media only screen and (min-width: 1180px) {
    height: 647px;
    padding-top: 0;
  }

  .marque__grid {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    & > * {
      height: 100%;
    }
  }
}

.marque--carousel__button {
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 29px;
  height: 48px;
  z-index: 10;
  cursor: pointer;

  &.swiper-button-disabled {
    opacity: 0;
    display: none;
  }

  // Arrows
  &.button-next {
    right: 10%;
    background-repeat: no-repeat;

    @media only screen and (min-width: $screen-lg-min) {
      right: 20%;
    }
  }

  &.button-prev {
    left: 10%;
    background-repeat: no-repeat;

    @media only screen and (min-width: $screen-lg-min) {
      left: 20%;
    }
  }
}

.marque-area {
  width: 100%;
  height: 100%;
  position: relative;

  &--image,
  &--video {
    display: flex;
    justify-content: center;

    position: relative;
  }
}

.marque-area__image,
.marque-area__video {
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: $screen-lg-min) {
    &::after {
      content: '';
      position: absolute;
      top: -30%;
      left: 0;
      width: 100%;
      height: 160%;

      transition: height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      height: 150%;
      box-shadow: inset 0px -80px 60px 100px #fff;
    }
  }

  .bmpui-ui-uicontainer {
    z-index: 99;
  }

  .bmpui-ui-controlbar,
  .bmpui-ui-titlebar {
    background: none;
  }

  &.container {
    @media (min-width: $screen-md-min) {
      width: 100%;
      max-width: 1180px;
    }
  }
}

.marque-area--hero-switch {
  margin-bottom: -20px;
}

.marque-area__image img {
  width: 100%;
}

// Marque cta
.marque-cta {
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.2);
  color: $color_links;
  display: inline-flex;

  padding: {
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
  }

  @media (min-width: $screen-md-min) {
    padding-right: 30px;
  }
}

.marque-cta__container {
  position: absolute;
  text-align: right;
  left: 0;
  right: 0;
  z-index: 9999;

  bottom: auto;
  top: 20px;

  @media (min-width: $screen-md-min) {
    bottom: 90px;
    top: auto;
  }
}

.marque-cta__icon {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  .generic-arrow-down .cls-1 {
    fill: $color_links;
  }

  svg {
    transform: rotate(-90deg);
    width: 40px;
  }

  @media (min-width: $screen-md-min) {
    width: 50px;
    height: 50px;
  }
}

.marque-cta__text {
  text-align: left;
  display: none;
  line-height: 1;

  @include font-size(18);

  &--external {
    align-self: center;
  }

  & > span {
    display: block;
    @include font-size(23);
  }

  @media (min-width: $screen-md-min) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
