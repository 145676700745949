.challenge-type-filter {
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 470px) {
    margin: 0 auto;
    max-width: 260px;
  }

  @media only screen and (min-width: $screen-md-min) {
    justify-content: space-around;
    flex-wrap: nowrap;
  }

  li {
    list-style: none;
    margin: 2.5px;

    @media only screen and (min-width: $screen-md-min) {
      margin: 0 2.5px;
    }

    img {
      cursor: pointer;
    }
  }
}

.challenge-filter-type-icon {
  position: relative;
  cursor: pointer;
  border: 2px #fff solid;
  border-radius: 50%;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);

  width: 45px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: $screen-lg-min) {
    width: 60px;
    height: 60px;
  }

  svg {
    max-width: 60%;

    &.challenge-schools-icon {
      max-width: 70%;
    }
  }

  &--travel {
    background-color: $travel_type;
    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-travel-icon .cls-1 {
        fill: #fbfbfb;
      }
      .challenge-travel-icon .cls-2 {
        fill: #fbfbfb;
      }
      .challenge-travel-icon .cls-5 {
        stroke: #fbfbfb;
      }
    }

    .challenge-filter-type-icon__title {
      color: $travel_type;
      border-color: $travel_type;
    }
  }
  &--creative {
    background-color: $creative_type;
    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-creative-icon .cls-1 {
        fill: #fbfbfb;
      }
    }

    .challenge-filter-type-icon__title {
      color: $creative_type;
      border-color: $creative_type;
    }
  }
  &--energy {
    background-color: $energy_type;
    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-energy-icon .cls-2 {
        fill: #ffffff;
      }
      .challenge-energy-icon .cls-3 {
        fill: #ffffff;
      }
      .challenge-energy-icon .cls-4 {
        stroke: #ffffff;
      }
    }

    .challenge-filter-type-icon__title {
      color: $energy_type;
      border-color: $energy_type;
    }
  }
  &--flora {
    background-color: $flora_type;

    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-flora-icon .cls-1 {
        fill: #fbfbfb;
      }
    }

    .challenge-filter-type-icon__title {
      color: $flora_type;
      border-color: $flora_type;
    }
  }
  &--fauna {
    background-color: $fauna_type;
    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-fauna-icon .cls-1 {
        fill: #fbfbfb;
      }
    }

    .challenge-filter-type-icon__title {
      color: $fauna_type;
      border-color: $fauna_type;
    }
  }
  &--recycle {
    background-color: $recycle_type;

    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-recycle-icon .cls-1 {
        fill: #f7f7f7;
      }
      .challenge-recycle-icon .cls-3 {
        fill: #f2f2f2;
      }
    }

    .challenge-filter-type-icon__title {
      color: $recycle_type;
      border-color: $recycle_type;
    }
  }
  &--water {
    background-color: $water_type;

    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-water-icon .cls-1 {
        fill: #d6d6d6;
        stroke: #f2f2f2;
      }
    }

    .challenge-filter-type-icon__title {
      color: $water_type;
      border-color: $water_type;
    }
  }
  &--schools {
    background-color: $schools_type;

    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-schools-icon .cls-1 {
        fill: #fbfbfb;
      }
    }

    .challenge-filter-type-icon__title {
      color: $schools_type;
      border-color: $schools_type;
    }
  }
  &--food {
    background-color: $food_type;

    &.challenge-filter-type-icon--deactive {
      background-color: #d6d6d6;

      .challenge-food-icon .cls-2 {
        fill: #fbfbfb;
      }
    }

    .challenge-filter-type-icon__title {
      color: $food_type;
      border-color: $food_type;
    }
  }
}

// Filter title ** only on desktop hover
.challenge-filter-type-icon__title {
  display: none;
}

.no-touchevents .challenge-filter-type-icon:hover {
  .challenge-filter-type-icon__title {
    transform: translate(-50%, -65px);
    opacity: 1;
  }
}

.no-touchevents .challenge-filter-type-icon__title {
  display: block;
  padding: 10px 25px;
  position: absolute;
  transform: translate(-50%, -55px);
  left: 50%;
  opacity: 0;
  z-index: 10;

  background-color: #fff;
  border-radius: 25px;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  @include font-size(22);
}
