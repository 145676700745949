.mygoals__levels__container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 990;
  background-color: rgba(5, 79, 86, 0.8);

  &--show {
    display: block;
  }

  &--hide {
    display: none;
  }
}

.mygoals__level__show-button {
  width: 55px;
  height: 55px;
  position: absolute;
  right: 15px;
  top: 15px;

  background-color: $color_feature;
  border: 4px #fff solid;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  z-index: 1001;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: rotate(0deg);
    transition: 0.3s transform ease-in-out;
  }

  &--close {
    svg {
      transform: rotate(180deg);
    }

    .title {
      display: none;
    }
  }
}

.mygoals__level__show-button .title {
  position: absolute;
  bottom: -40px;
  background-color: $color_feature;
  border: 3px #fff solid;
  border-radius: 10px 0;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  p {
    color: #fff;
    @include font-size(14);
  }
}

.mygoals__change-garden {
  text-align: center;

  button {
    border: none;
    background-color: transparent;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    background-color: $color_feature;
    display: inline-block;
    cursor: pointer;
    border: none;
    min-width: 180px;
    margin: 0 auto 10px;
    padding: 10px 15px;
    border-radius: 25px 0;

    @include font-size(26);

    &:hover {
      background-color: #ffffff;
      color: $color_feature;
    }
  }
}

.mygoals__current-level {
  background: rgb(250, 188, 44);
  background: -moz-linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  background: linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fabc2c",endColorstr="#feeb34",GradientType=1);

  display: flex;
  align-items: center;
  padding: 15px 30px;
  color: #000;

  @include font-size(26);
}

.mygoals__current-level .mygoals__level--progress {
  .name {
    @include font-size(18);

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(20);
    }
  }
  .icon {
    margin-left: auto;
    width: 18px;

    @media only screen and (min-width: $screen-lg-min) {
      width: 20px;
    }
  }
}

.mygoals__challenges-completed {
  margin-left: auto;
  margin-right: 60px;
  span {
    display: block;
    width: 100%;
    text-align: right;

    &.text {
      font-family: $font__avenir--demi;
      @include font-size(20);
    }

    &.count {
      font-family: $font__avenir--bold;
      @include font-size(20);
    }
  }
}

.mygoals__current-level .mygoals__level--progress {
  min-width: 300px;
  padding: 10px;
  margin-left: 20px;
}

.mygoals__levels {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  list-style: none;
  padding: 20px 20px 0;

  li {
    flex-basis: calc(33.33% - 20px);

    &:nth-child(3n + 0) {
      margin-left: auto;
    }
    margin-right: 10px;
    margin-left: 10px;

    margin-bottom: 20px;
  }
}

.mygoals__level {
  position: relative;
  color: #fff;
  background-color: #3bc8d6;
  border-radius: 15px 0;
  padding: 5px 10px;
  border: 4px #fff solid;
  overflow: hidden;
  min-height: 55px;
  height: 100%;
  display: flex;
  align-items: center;

  .name {
    margin-right: 5px;
    @include font-size(20);
  }
  .icon {
    margin-left: auto;
    width: 100%;
    max-width: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mygoals__level__details {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
}

.mygoals__level__percentage {
  background: rgb(43, 127, 61);
  background: -moz-linear-gradient(90deg, rgba(43, 127, 61, 1) 0%, rgba(57, 168, 81, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(43, 127, 61, 1) 0%, rgba(57, 168, 81, 1) 100%);
  background: linear-gradient(90deg, rgba(43, 127, 61, 1) 0%, rgba(57, 168, 81, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2b7f3d",endColorstr="#39a851",GradientType=1);
  border-radius: 0 0 10px 0;
  transition: width 0.2s;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
