.site-logo a {
  position: absolute;
  height: auto;
  z-index: 999;
  transition: all 0.4s ease;
  top: 0;
  left: 0;
  margin: 0 auto;

  @media (min-width: $screen-lg-min) {
    top: 15px;
    left: 15px;
  }
}

.site-logo,
.site-logo img {
  width: 80px;
  height: 100%;
  position: relative;

  @media (min-width: $screen-sm-min) {
    width: 150px;
  }

  @media (min-width: $screen-sm-min) and (orientation: landscape) {
    width: 110px;
  }

  @media (min-width: $screen-lg-min) {
    width: 200px;
  }
}
