.btn_icon_label {
  padding: 10px 20px 10px;
  height: 44px;
  color: #000000;
  border-radius: 22px;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  @include font-size(25);
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  cursor: pointer;

  .label,
  .icon {
    position: relative;
    display: inline-block;
  }

  .icon {
    height: 25px;
    max-width: 50px;

    svg {
      width: auto;
      height: 100%;
    }
  }

  &.green {
    background: $color_links;

    &:hover {
      background: white;
    }
  }
  &.white {
    background: white;

    &:hover {
      background: $color_links;
    }
  }
}
