html[lang='en'] {
  line-height: 1.15;

  .challenges-counter__text {
    @media only screen and (min-width: $screen-md-min) {
      @include font-size(32);
    }
  }

  .mygoals_introheader {
    line-height: 0.9;
  }

  .mygoals_step .mygoals_stepcopy {
    line-height: 1;
  }

  .rotate-screen__text {
    line-height: 0.8;
  }
}
