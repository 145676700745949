// Swiper pagination
.swiper-pagination {
  text-align: left;
  margin-left: 10px;

  @media only screen and (min-width: $screen-md-min) {
    margin-left: 0;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;

    &.swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }
}

.swiper-slide {
  line-height: 0;
  align-self: center;
}
