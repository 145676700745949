.challenge-filter__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media only screen and (min-width: $screen-md-min) {
    flex-direction: row;
  }
}

.challenge-filter__type {
  @media only screen and (min-width: $screen-md-min) {
    margin-left: auto;
  }
}

.challenge-filter__dropdown {
  margin-bottom: 20px;
  align-self: center;

  @media only screen and (min-width: $screen-md-min) {
    align-self: flex-start;

    flex-basis: 230px;
    margin-right: 20px;
  }
  @media only screen and (min-width: $screen-lg-min) {
    flex-basis: 300px;
  }
}
