.footer {
  margin-top: 40px;
  background-color: #000;
  font-family: $font__avenir;
  text-align: center;
  position: relative;
  overflow: hidden;

  padding: {
    top: 20px;
    bottom: 20px;
  }

  @media (min-width: $screen-md-min) {
    text-align: left;

    padding: {
      top: 65px;
      bottom: 45px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }
}

.footer__left-section {
  flex-basis: 100%;

  @media (min-width: $screen-md-min) {
    margin-right: 50px;
    border-right: 1px #323232 solid;
    max-width: 200px;
  }
}

.footer__right-section {
  flex-basis: 100%;
}
