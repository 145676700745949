// Setup colours for across the site
$color_feature: #ec0080;
$color_links: $color_feature; //:#ff6553;
$color_earth_bg: #90495f;
$color_space_bg: #10164e;
$color_default_bg: #111344;

// Task type colours
$travel_type: $color_feature;
$creative_type: #8c08ff;
$energy_type: #ffc100;
$flora_type: #85b900;
$fauna_type: #fe6e56;
$recycle_type: #00b04b;
$water_type: #00bdb6;
$food_type: #3ec407;
$schools_type: #c96ccb;
