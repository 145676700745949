@media (orientation: landscape) {
  html.mobile body:not(.my-goals) .mygoalspage__frame {
    max-height: 100vh;
    max-width: 100vw;
    margin: 60px auto 0;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 56%;
    border: solid 4px #fff;
    border-radius: 40px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);

    & > div {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.mygoals_frame {
  background: rgba(0, 255, 0, 0.1);
  max-height: 100vh;
  max-width: 100vw;
  margin: 2rem auto 0;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: math.div(1020px, 1800px) * 100%;
  border: solid 4px #fff;
  border-radius: 40px;
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.35);
}

.mygoals_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    max-width: 110%;
  }
}

.mygoals_rotateme {
  @include font-size(28);
}

// INTRO =========

.mygoals_intro {
  position: relative;
  height: 100%;
  width: 100%;
}

.mygoals_intro__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.mygoals_intro__body {
  position: relative;
  z-index: 2;
  height: 100%;
}

// .mygoals_intro__body--choose-garden {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-content: center;
// }

.mygoals_introheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  padding: 25px 10%;
  text-align: center;
  height: 30%;
  line-height: 1.2;
  color: #fff;
  @include font-size(24);

  span {
    @include font-size(32);
    display: block;
  }
}

.mygoals_steps {
  padding: 0 10%;
  text-transform: uppercase;
  position: relative;
  top: 0;
  left: 0;
  height: 40vh;
  width: 100%;
  display: flex;
  color: #000;

  background: rgb(255, 236, 0);
  background: -moz-linear-gradient(0deg, rgba(255, 236, 0, 1) 0%, rgba(251, 188, 0, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 236, 0, 1) 0%, rgba(251, 188, 0, 1) 100%);
  background: linear-gradient(0deg, rgba(255, 236, 0, 1) 0%, rgba(251, 188, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffec00",endColorstr="#fbbc00",GradientType=1);

  @media only screen and (min-width: $screen-md-min) {
    position: absolute;
    height: 40%;
    top: 30%;
  }
}

.mygoals_step {
  display: flex;

  .mygoals_stepcopy {
    width: 60%;
    margin: 5px 0;
    @include font-size(24);
    line-height: 1.2;

    @media only screen and (min-width: $screen-lg-min) {
      width: 40%;
    }
    span {
      display: block;
      @include font-size(20);
      margin-bottom: 5px;
    }
  }

  .mygoals_stepimage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;

    @media only screen and (min-width: $screen-lg-min) {
      width: 60%;
    }
  }

  img {
    height: 90%;
    object-fit: contain;

    @media only screen and (min-width: $screen-lg-min) {
      max-height: 100%;
      height: auto;
    }
  }
  img.breakOut {
    @media only screen and (min-width: $screen-lg-min) {
      height: 100%;
      margin-top: -10%;
    }
  }

  .mygoals_steps__button {
    text-align: center;
  }

  .authModal__button {
    width: 100%;
    max-width: 350px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80px;
    padding: 10px 20px;
    @include font-size(28);

    @media (min-width: $screen-lg-min) {
      bottom: -120px;
      padding: 15px 40px;
      @include font-size(32);
    }
  }
}

.mygoals_stepcontrol {
  position: absolute;
  bottom: 0px;
  left: 10%;
  display: flex;
  span {
    padding: 0;
    cursor: pointer;
    opacity: 0.2;
    @include font-size(37);
    &.active {
      opacity: 1;
    }
  }
}

// My Goals Choose ==========

.mygoals_choice {
  position: relative;
  height: 100%;
  width: 100%;
}

.mygoals_choiceheader {
  margin: 20px 0 0;
  text-transform: uppercase;
  padding: 0;
  text-align: center;
  color: #fff;
  @include font-size(20);
  @media (min-width: $screen-md-min) {
    @include font-size(36);
  }
}

.challenges-grid {
  list-style: none;
  margin: 0;
  padding: 0;

  margin-top: 20px;

  &--full {
    @media only screen and (min-width: $screen-md-min) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    li {
      flex-basis: 100%;
      margin-bottom: 20px;

      @media only screen and (min-width: $screen-md-min) {
        flex-basis: calc(50% - 10px);

        &:nth-child(2n + 0) {
          margin-left: auto;
        }
      }
    }
  }
}

.mygoals_choicegrid {
  padding: 2vw 16%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media only screen and (min-width: $screen-lg-min) {
    padding: 2vw 10%;
  }

  .mygoals_gardenchoice {
    flex-basis: calc(50% - 10px);

    &:nth-child(2n + 0) {
      margin-left: auto;
    }
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    margin: 5px;
    box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: $screen-md-min) {
      border-radius: 30px;
    }

    @media only screen and (min-width: $screen-lg-min) {
      border-radius: 40px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .mygoals_confirm {
      padding: 15px;
      display: none;
      text-align: center;
      position: absolute;
      font-family: $font__lubalinBold;
      background-color: $color_links;
      width: 100%;
      border-radius: 30px 0 30px 0;

      @include font-size(24);

      @media (min-width: $screen-md-min) {
        padding: 20px 0;
        border-radius: 50px 0 50px 0;

        @include font-size(30);
      }
      @media (min-width: $screen-lg-min) {
        @include font-size(50);
      }
      svg {
        display: inline;
        width: 24px;
        margin: 0 10px -2.5px 0;

        @media (min-width: $screen-md-min) {
          width: 30px;
        }
        @media (min-width: $screen-lg-min) {
          width: 50px;
        }
      }
    }
    &.active {
      margin: 0px;
      border: solid 5px white;

      .mygoals_confirm {
        display: block;
      }
    }
  }
}

.mygoals_garden {
  height: 100%;
  width: 100%;
  background-position: center;

  .draggable-plant {
    position: absolute;
    cursor: move;

    &.index-1 {
      z-index: 1;
    }
    &.index-2 {
      z-index: 2;
    }
    &.index-3 {
      z-index: 3;
    }

    img.pulse {
      transform-origin: center bottom;
      animation: 1s pulse infinite;
    }
  }
  .react-draggable-dragging {
    z-index: 999 !important;
  }
}

.mygoals_garden__placements {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;

  transition: 0.1s opacity ease-in-out;

  &.is-dragging {
    opacity: 1;
  }

  .placement_marker {
    width: 98px;
    height: 25px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;

    &--active {
      background-color: rgba(236, 0, 128, 0.75);
    }
  }
}

.mygoals__container {
  background-position: center;
  background-repeat: no-repeat;

  background-size: auto 100%;
}

// mobile styling
html.mobile body.my-goals {
  @media only screen and (orientation: landscape) {
    header,
    footer {
      display: none;
    }

    #app {
      height: 100%;
      width: 100%;
    }

    .mygoalspage__frame {
      margin-top: 0;
      margin-bottom: 0;
      &.container {
        width: 100%;
        padding: 0;
        overflow: hidden;
      }
    }

    .mygoals__container {
      overflow: hidden;
    }

    .mygoals_choiceheader {
      margin: 0;
    }
  }
}
