// hover/focus states for desktop
.no-touchevents {
  .emoji-item {
    &:hover,
    &:focus {
      .emoji-item__label {
        visibility: visible;
        top: -50px;
        opacity: 1;

        @media (min-width: $screen-sm-min) {
          top: -70px;
        }
      }
    }
  }

  .emoji-item__icon:hover {
    transform: scale(1.1);
  }
}

// Hover events for touch devices
.emoji-item.hovering {
  .emoji-item__label {
    visibility: visible;
    top: -50px;
    opacity: 1;

    @media (min-width: $screen-sm-min) {
      top: -70px;
    }
  }

  .emoji-item__icon:hover {
    transform: scale(1.1);
  }
}

.emoji-item {
  margin: {
    right: 8px;
    bottom: 10px;
    left: 8px;
  }

  flex-direction: column;

  @media (min-width: $screen-sm-min) {
    margin: {
      right: 12px;
      left: 12px;
    }
  }

  @media (min-width: $screen-md-min) {
    position: relative;
  }
}

.emoji-item__container {
  position: relative;
}

.emoji-item__label {
  display: block;

  visibility: hidden;
  @include font-size(13);

  margin-bottom: 5px;
  text-align: center;

  opacity: 0;
  position: absolute;
  top: -45px;
  left: calc(50% - 100px);
  z-index: 1;
  text-align: center;

  white-space: nowrap;
  width: 200px;

  transition: font-size 0.3s ease-in-out, top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;

  @media (min-width: $screen-sm-min) {
    @include font-size(26);

    top: -65px;
  }

  @media (min-width: $screen-md-min) {
    &:after {
      content: '';
      background-color: #fff;
      box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.35);

      bottom: -4px;
      left: calc(50% - 5px);
      position: absolute;
      height: 10px;
      width: 10px;

      transform: rotate(45deg);
    }
  }

  .label {
    background-color: #fff;
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35), 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    color: #000;
    border-radius: 25px;
    padding: 5px 10px;
    margin: auto;
    width: fit-content;
  }
}

.emoji-item__label-container {
  text-align: center;
  padding-bottom: 4px;
}

.emoji-item__icon {
  position: relative;
  bottom: 5px;
  cursor: pointer;
  align-self: flex-start;
  transform: scale(1);
  transition: bottom 0.2s ease-in-out, transform 0.2s ease-in-out;

  height: 35px;

  @media (min-width: $screen-sm-min) {
    height: 60px;
    bottom: 10px;
  }

  &--liked {
    bottom: 15px;
    transform: scale(1.4);

    @media (min-width: $screen-sm-min) {
      bottom: 25px;
    }
  }

  &--container {
    position: relative;
  }
}

.emoji-item__count {
  margin-top: -2px;
  .count {
    background-color: #000;
    border-radius: 25px;
    padding: 3px 12px;
    text-align: center;
    @include font-size(12);

    @media (min-width: $screen-md-min) {
      @include font-size(16);
    }
  }
}
