.schoolspage__ideas {
  background: rgb(28, 121, 187);
  background: -moz-linear-gradient(0deg, rgba(28, 121, 187, 1) 0%, rgba(53, 171, 224, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(28, 121, 187, 1) 0%, rgba(53, 171, 224, 1) 100%);
  background: linear-gradient(0deg, rgba(28, 121, 187, 1) 0%, rgba(53, 171, 224, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c79bb",endColorstr="#35abe0",GradientType=1);
}

.schoolspage__ideas__title {
  padding: 40px 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;

  @include font-size(20);

  @media only screen and (min-width: $screen-md-min) {
    width: 70%;
    @include font-size(35);
  }
}

.schools__super-challenges__button {
  font-family: $font__lubalinBold;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  background-color: $color_links;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  min-width: 160px;
  margin: 0 auto 10px;
  padding: 10px 10px;
  border-radius: 25px 0 25px 0;
  @include font-size(20);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }

  .text {
    padding: 0 10px;
  }

  svg {
    width: 20px;
  }
}

.schools__super-challenges__header {
  color: #000;
  width: 80%;
  position: relative;
  margin-bottom: 50px;
  @include font-size(28);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(40);
    margin-bottom: 40px;
  }
}

.schools__super-challenges__icon {
  position: absolute;
  top: 0;
  right: -25%;
  z-index: 1;
  transform: scale(0.6);
  transform-origin: top right;

  @media only screen and (min-width: $screen-lg-min) {
    transform: scale(0.8);
  }
}

.schools__more-challenges__button {
  font-family: $font__lubalinBold;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  background-color: $color_links;
  display: block;
  cursor: pointer;
  border: none;
  min-width: 160px;
  margin: 20px auto 10px;
  padding: 10px 10px;
  padding-left: 50px;
  border-radius: 25px 0 25px 0;
  @include font-size(20);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }

  svg {
    position: absolute;
    height: 50px;
    width: 50px;
    left: -12px;
    top: -4px;

    @media only screen and (min-width: $screen-md-min) {
      height: 60px;
      width: 60px;
      position: absolute;
      left: -20px;
      top: -5px;
    }
  }
}

.schools__super-challenges__item {
  border-radius: 15px;
  border: 1px #000 solid;
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  font-family: $font__avenir;
  color: #000;
  position: relative;
  min-height: 60px;

  svg {
    left: 13px;
    position: absolute;
    top: 15px;
    width: 25px;
  }
}
