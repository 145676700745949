.legal__links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.legal__link {
  margin-bottom: 20px;
  width: 100%;

  button.link {
    background: none;
    border: none;
    color: #fff;
    padding: 0;
  }

  .link {
    @include font-size(15);

    &:hover {
      color: #51879b;
    }
  }
}
