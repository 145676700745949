.content-item {
  border-radius: 25px 0 25px 0;
  position: relative;
  overflow: hidden;

  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5);

  @media only screen and (min-width: $screen-md-min) {
    &:hover {
      .content-item__details {
        bottom: 0;
        transition: bottom 0.4s ease-in-out;
      }

      .content-item__title {
        bottom: 0;
        transition: bottom 0.3s 0.15s ease-in-out;
      }

      .content-item__image img,
      .content-item__video video {
        transform: scale(1.1);
      }
    }
  }
}

.content-item__image img,
.content-item__video video {
  transition: transform 0.2s ease-in-out;
}

.content-item__video {
  line-height: 0;
  video {
    width: 100%;
  }
}

.content-item__details {
  color: #fff;
  padding: 10px;
  line-height: 1;
  text-align: left;
  bottom: 0;
  position: absolute;
  width: 100%;

  display: flex;
  align-items: center;

  transition: bottom 0.4s ease-in-out;

  @media only screen and (min-width: $screen-md-min) {
    bottom: -200px;
  }
}

.content-item--quiz .content-item__details {
  background-color: #0055a4;
}

.content-item--challenge .content-item__details {
  background-color: #9052c6;
}

.content-item--video .content-item__details {
  background-color: #9052c6;
}

.content-item--external .content-item__details,
.content-item--internal .content-item__details,
.content-item--existing .content-item__details {
  background-color: $color_feature;
}

.content-item__type {
  text-decoration: underline;
  margin-bottom: 2px;
  position: relative;
}

.content-item__title {
  @include font-size(12);

  position: relative;

  @media (min-width: $screen-sm-min) {
    @include font-size(16);
  }

  @media (min-width: $screen-lg-min) {
    @include font-size(14);
    margin-right: 10px;
  }

  h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-family: $font__avenir;
  }
}

// default svg width
.content-item__icon {
  margin-left: auto;
  .icon svg {
    width: 30px;
    height: 30px;

    @media (min-width: $screen-md-min) {
      width: 35px;
      height: 35px;
    }
  }
}

// Large Content Item Styling
.content-item--large {
  @media (min-width: $screen-md-min) {
    .content-item__details {
      padding: 15px;
    }
  }

  .content-item__title {
    margin-right: 10px;

    @media (min-width: $screen-md-min) {
      @include font-size(38);
    }
  }
  .content-item__icon .icon svg {
    width: 30px;
    height: 30px;

    @media (min-width: $screen-md-min) {
      width: 60px;
      height: 60px;
    }
  }
}

// Hero Content Item Styling
.content-item--hero {
  .content-item__details {
    padding: 20px 15px;

    @media (min-width: $screen-md-min) {
      padding: 40px 45px;
    }

    @media only screen and (max-width: 1024px) {
      position: relative;
      bottom: 0px;
    }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      position: relative;
      bottom: 0px;
    }
  }
  .content-item__title {
    margin-right: 5px;
    h1 {
      margin: 0;

      @media (min-width: $screen-md-min) {
        @include font-size(52);
      }
    }
  }
  .content-item__icon .icon svg {
    width: 30px;
    height: 30px;

    @media (min-width: $screen-md-min) {
      width: 100px;
      height: 100px;
    }
  }
}

// External Content Item Only Styling
.content-item--external,
.content-item--internal {
  .content-item__details {
    padding-top: 10px;

    // @media (min-width: $screen-md-min) {
    //   padding-top: 20px;
    // }
  }
  .content-item__icon {
    bottom: 5px;
    right: 5px;

    @media (min-width: $screen-md-min) {
      bottom: 15px;
      right: 15px;
    }
  }
}

// Mixed Sizes content item
.content-item--size-mix {
  .content-item__icon .icon svg {
    width: 60px;
    height: 60px;

    @media (min-width: $screen-md-min) {
      width: 50px;
      height: 50px;
    }
  }
}
