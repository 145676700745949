.challenges-grid {
  list-style: none;
  margin: 0;
  padding: 0;

  margin-top: 20px;

  min-height: 208px;

  // @media only screen and (min-width: $screen-md-min) {
  //   min-height: 1415px;
  // }

  &--full {
    @media only screen and (min-width: $screen-md-min) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    li {
      flex-basis: 100%;
      margin-bottom: 20px;

      @media only screen and (min-width: $screen-md-min) {
        flex-basis: calc(50% - 10px);

        &:nth-child(2n + 0) {
          margin-left: auto;
        }
      }
    }
  }
}

.challenges-grid__more-button {
  border: none;
  background-color: #fff;
  color: $color_feature;
  border-radius: 25px 0;
  cursor: pointer;

  padding: 10px 50px;
  margin: 0 auto;
  display: flex;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  @include font-size(26);
}

.no-touchevents {
  .challenges-grid__more-button:hover {
    color: #fff;
    background-color: $color_feature;
  }
}
