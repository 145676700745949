//import fonts here!!!!
$font_path: '../fonts';

// @font-face {
//   font-family: 'Lubalin Graph ITC Turner Latin';
//   font-display: swap;
//   src: url('#{$font_path}/lubalin/LubalinGraphITCTurner.eot');
//   src: url('#{$font_path}/lubalin/LubalinGraphITCTurner.eot?#iefix') format('embedded-opentype'), url('#{$font_path}/lubalin/lubalin.woff') format('woff'),
//     url('#{$font_path}/lubalin/LubalinGraphITCTurner.ttf') format('truetype'), url('#{$font_path}/lubalin/LubalinGraphITCTurner.svg#CNRegular') format('svg');
// }
//
// // ARABIC
// @font-face {
//   font-family: 'Lubalin Graph ITCTurnerAraW03B';
//   font-display: swap;
//   src: url('#{$font_path}/lubalin/acb449e5-5a1f-4de5-bde2-dc279da9b1ea.eot?#iefix');
//   src: url('#{$font_path}/lubalin/acb449e5-5a1f-4de5-bde2-dc279da9b1ea.eot?#iefix') format('embedded-opentype'), url('#{$font_path}/lubalin/9d704a14-5422-42e5-9593-c3058bf31035.woff2') format('woff2'),
//     url('#{$font_path}/lubalin/822e1975-6bb1-44bd-abb9-6a9db91c54ab.woff') format('woff'), url('#{$font_path}/lubalin/fc9ee47d-9dfe-48ce-92e2-41caa651c657.ttf') format('truetype'),
//     url('#{$font_path}/lubalin/3f65929b-b81b-4c90-82d3-1780314f22df.svg#3f65929b-b81b-4c90-82d3-1780314f22df') format('svg');
// }
//
// // avenir fonts
// @font-face {
//   font-family: 'avenir-rounded';
//   font-display: swap;
//   src: url('#{$font_path}/avenir/5c1a4364-a05a-40cc-91c8-2b4b02e3cb6d.eot');
//   src: url('#{$font_path}/avenir/5c1a4364-a05a-40cc-91c8-2b4b02e3cb6d.eot?#iefix') format('embedded-opentype'), url('#{$font_path}/avenir/8643f4bf-7a5c-4e46-8978-9cfb13f32c2e.woff') format('woff'),
//     url('#{$font_path}/avenir/8d4e35f6-bcdf-4b87-ad3e-9d23a335af60.ttf') format('truetype'),
//     url('#{$font_path}/avenir/321da546-bf6e-4014-adee-9cd39923a541.svg#321da546-bf6e-4014-adee-9cd39923a541') format('svg');
// }

@font-face {
  font-family: 'avenir-rounded-demi';
  font-display: swap;
  src: url('#{$font_path}/avenir/AvenirNextRoundedPro-Demi.ttf') format('truetype');
}

@font-face {
  font-family: 'avenir-rounded-bold';
  font-display: swap;
  src: url('#{$font_path}/avenir/AvenirNextRoundedPro-Bold.ttf') format('truetype');
}
