.refresh-message {
  display: none;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  box-shadow: inset 0px -5px 12px 0px rgba(0, 0, 0, 0.35);
  padding-top: 20px;
  padding-bottom: 20px;
}

.refresh-message__button {
  display: block;
  background-color: #1fbbec;
  padding: 10px;
  max-width: 200px;
  margin: 10px auto;
  border-radius: 50px;
  color: #ffffff;
}
