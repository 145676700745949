// General page layout
html.webp {
  .lower-section {
    background-image: url('../images/coming-soon/coming-soon-footer.webp');
  }

  .coming-soon-page__container__bg {
    background-image: url('../images/coming-soon/bg.webp');
  }
}

body.coming-soon {
  .bg-wrapper::before {
    display: none;
  }
}

body.coming-soon footer {
  margin-top: 0;
}
.coming-soon-footer {
  position: static !important;
}

.upper-section {
  background: rgb(1, 166, 234);
  background: -moz-linear-gradient(180deg, rgba(1, 166, 234, 1) 0%, rgba(1, 199, 238, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(1, 166, 234, 1) 0%, rgba(1, 199, 238, 1) 100%);
  background: linear-gradient(180deg, rgba(1, 166, 234, 1) 0%, rgba(1, 199, 238, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01a6ea",endColorstr="#01c7ee",GradientType=1);
}

.lower-section {
  padding: 20px 0;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background-image: url('#{$image_path}/coming-soon/coming-soon-footer.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  min-height: 200px;

  @media (min-width: $screen-sm-min) {
    min-height: 250px;
  }

  @media (min-width: $screen-md-min) {
    min-height: 350px;
  }

  @media (min-width: $screen-lg-min) {
    min-height: 400px;
  }

  @media (min-width: $screen-xl-min) {
    min-height: 556px;
  }

  .mpu-leader {
    margin-bottom: 0px;
  }

  .container {
    margin-bottom: 20px;
  }
}

.coming-soon-page {
  text-align: center;
  position: relative;
  z-index: 1;
}

.coming-soon-page__title,
.coming-soon-page__subtitle {
  margin: 0;
}

// Heading styles
.coming-soon-page__title {
  @include font-size(30);
  margin-bottom: 20px;

  @media (min-width: $screen-md-min) {
    @include font-size(50);
    margin-bottom: 50px;
  }
}

.coming-soon-page__subtitle {
  @include font-size(26);
  margin-bottom: 20px;

  @media (min-width: $screen-md-min) {
    @include font-size(40);
    margin-bottom: 35px;
  }

  @media (min-width: $screen-lg-min) {
    @include font-size(50);
    margin-bottom: 35px;
  }
}

// logo
.coming-soon-page .logo {
  max-width: 480px;
  margin: {
    top: 0;
    right: auto;
    bottom: 50px;
    left: auto;
  }

  @media (orientation: landscape) and (max-width: $screen-md-min) {
    margin-bottom: 0;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 700px;
  }
}

.coming-soon-page__body {
  max-width: 740px;
  margin: 0 auto;
  font-family: $font__avenir;

  p {
    font-family: $font__avenir;
  }
}

.coming-soon-page__container {
  width: 100%;
  background: rgb(230, 1, 126);
  background: -moz-linear-gradient(180deg, rgba(230, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(230, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  background: linear-gradient(180deg, rgba(230, 1, 126, 1) 0%, rgba(151, 26, 129, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e6017e",endColorstr="#971a81",GradientType=1);
}
.coming-soon-page__container__bg {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url('#{$image_path}/coming-soon/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 95%;

  @media only screen and (min-width: $screen-md-min) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1441px) {
    background-size: 80%;
  }
}

// Video overlay CTA
.coming-soon-page__cta {
  cursor: pointer;
  background-color: #fff;
  color: $color_feature;
  display: inline-block;
  border: none;
  padding: 5px 80px;
  margin: 30px 0;
  border-radius: 25px 0 25px 0;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
  @include font-size(18);

  @media (min-width: $screen-md-min) {
    margin: 100px 0;
    @include font-size(30);
  }
}

// Video overlay
.coming-soon-page__overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  padding-top: 60px;

  .bitmovinplayer-ad-container,
  .bitmovinplayer-container {
    max-width: 600px;
    max-height: 340px;
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    background-color: #fff;
    border-radius: 25px 0 25px 0;
    width: 55px;
    height: 45px;

    button {
      font-family: $font__avenir;
      border: none;
      background: none;
      cursor: pointer;
      font-weight: bold;
      color: #000;
      font-size: 26px;
      font-size: 1.625rem;

      @include font-size(20);

      @media (min-width: $screen-md-min) {
        @include font-size(26);
      }
    }
  }
}

.coming_soon_section__header,
.coming_soon_section__subheader {
  font-size: 40px;
  margin: 20px 0;
  display: block;
  position: relative;
  @media (min-width: $screen-sm-min) {
    font-size: 60px;
  }
}
