.quizpage {
  margin-top: 80px;
  min-height: 350px;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: 50px;
  }
  h1 {
    text-align: center;
  }
}
