.video-playlist {
  margin-bottom: 80px;
  position: relative;

  @media only screen and (min-width: $screen-md-min) {
    margin-bottom: 20px;
  }

  .content-item {
    box-shadow: none;
  }

  .content-item__details {
    bottom: 0;
  }
}

.video-playlist__header {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.video-playlist__button {
  margin-left: auto;
  border: none;
  background-color: $color_feature;
  color: #fff;
  padding: 10px 15px;
  @include font-size(20);
  text-transform: uppercase;
  border-radius: 25px 0 25px 0;
  position: relative;
  z-index: 201;
  display: flex;
  align-items: center;

  &--less {
    svg {
      transform: rotate(180deg);
    }
  }

  &__arrow {
    width: 20px;
    height: 20px;
    margin-left: 10px;

    display: flex;
    align-items: center;

    svg {
      transition: 0.3s transform ease-in-out;

      .cls-1 {
        fill: #fff;
      }
    }
  }

  @media only screen and (max-width: $screen-md-min) {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.video-playlist__title {
  color: #000;
  @include font-size(26);
  text-transform: uppercase;
  margin-bottom: 5px;
}

// Grid styling
.video-playlist__grid {
  list-style: none;
  margin: 0;
  padding: 0;

  @media only screen and (min-width: $screen-md-min) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  li {
    flex-basis: 100%;
    margin: 0 15px 20px;

    @media only screen and (min-width: $screen-md-min) {
      flex-basis: calc(33.3% - 20px);

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(3n + 0) {
        margin-right: 0;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  }
}

// carousel styling
.no-touchevents {
  .video-playlist__carousel:hover {
    .video-playlist__carousel__button {
      opacity: 0.5;
    }
  }

  .video-playlist__carousel__button {
    @media only screen and (min-width: $screen-md-min) {
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}

.video-playlist__carousel {
  position: relative;
}

.video-playlist__carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: $color_feature;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;
  width: 45px;
  height: 45px;
  z-index: 10;
  cursor: pointer;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: $screen-md-min) {
    opacity: 0;
  }

  &::before,
  &::after {
    position: absolute;
    background-color: #fff;
    content: '';
    height: 3px;
    width: 17px;
    z-index: 11;
  }

  &.swiper-button-disabled {
    opacity: 0;
    display: none;
  }

  // Arrows
  &.button-next {
    right: 5px;

    &::after {
      margin-top: 10px;
      transform: rotate(-45deg);
    }

    &::before {
      margin-top: -10px;
      transform: rotate(45deg);
    }
  }

  &.button-prev {
    left: 5px;

    &::after {
      margin-top: -10px;
      transform: rotate(-45deg);
    }

    &::before {
      margin-top: 10px;
      transform: rotate(45deg);
    }
  }
}

// RTL styling
.video-playlist__carousel .swiper-container[dir='rtl'] .video-playlist__carousel__button {
  &.button-next {
    right: auto;
    left: 5px;

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  &.button-prev {
    left: auto;
    right: 5px;

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}
