html.mobile {
  .navigation__items-group {
    background-color: rgba(0, 0, 0, 0.8);
    padding-bottom: 100%;
  }

  .navigation__wrapper {
    background-color: transparent;
  }
}

// General Navigation styling
.navigation__wrapper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  height: 0;
  transition: height 0.6s;

  &--active {
    height: 100%;
  }

  @media (min-width: $screen-lg-min) {
    position: relative;
    height: auto;
    width: auto;
    z-index: 5;
  }
}

.navigation__items-group {
  transform: translateY(-200%);
  transition: transform 0.6s;
  display: flex;
  justify-content: flex-end;

  list-style: none;
  padding: {
    top: 100px;
    right: auto;
    left: auto;
  }

  &--active {
    // haha 69...
    transform: translateY(69px);
  }

  @media (min-width: $screen-lg-min) {
    padding: 0;
    transform: none;
    justify-content: space-around;
    max-width: 780px;
  }
}

.navigation__link {
  margin-bottom: 35px;
  text-align: center;
  width: 100%;

  // Fallback styling when snazzy code below
  // not working
  @media (min-width: $screen-lg-min) {
    margin-bottom: 0;
  }

  &.active {
    .navigation__link__icon svg {
      .cls-2 {
        fill: $color_feature;
      }

      &.nav-challenge-icon {
        .cls-2 {
          fill: none;
          stroke: $color_feature;
        }
      }

      &.nav-schools-icon .cls-1 {
        fill: $color_feature;
      }
    }
    .navigation__link__title {
      color: $color_feature;
    }
  }
}

.navigation__link .link {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $screen-lg-min) {
    display: block;

    padding: {
      top: 30px;
      right: 5%;
      bottom: 30px;
      left: 5%;
    }
  }

  .login-button {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  // Hover / Active styling
  &:hover {
    .navigation__link__icon .user-login-img {
      border-color: $color_feature;
    }
    .navigation__link__icon svg {
      .cls-2 {
        fill: $color_feature;
      }

      &.nav-challenge-icon {
        .cls-2 {
          fill: none;
          stroke: $color_feature;
        }
      }

      &.nav-schools-icon {
        .cls-1 {
          fill: $color_feature;
        }
      }
    }

    .navigation__link__title {
      color: $color_feature;
    }
  }

  // Offline disabled styling
  &--disabled {
    opacity: 0.2;
  }
}

.navigation__link__icon {
  width: 100%;
  line-height: 0;

  .user-login-img {
    width: 45px;
    height: 45px;
    border: #fff 2px solid;
    border-radius: 50%;
  }

  svg {
    margin: {
      right: auto;
      left: auto;
    }

    height: 55px;
    width: 100%;

    @media (min-width: $screen-lg-min) {
      height: 45px;
      width: auto;
    }
  }
}

.navigation__link__title {
  color: #fff;
  margin-top: 10px;
  text-transform: uppercase;

  align-self: center;
  width: 100%;

  @media (min-width: $screen-lg-min) {
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: $screen-lg-min) {
    position: relative;
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(11);
  }
}

.navigation__items-group .navigation__link .link {
  .login-button {
    display: none;

    @media only screen and (min-width: $screen-lg-min) {
      display: block;
    }
  }
}

//*****
// Menu Burger Icon
// ******
.navigation__toggle,
.navigation__mobile-login {
  position: absolute;
  top: 15px;
  right: 22px;

  z-index: 999;
}

.navigation__mobile-login {
  right: 75px;

  .login-button {
    display: flex;
    justify-content: flex-end;
    flex-basis: 200px;

    @media only screen and (min-width: $screen-lg-min) {
      display: none;
    }
  }

  .navigation__link__icon {
    order: 2;
    margin-left: 10px;
    min-width: 42px;

    svg,
    .user-login-img {
      height: 42px;
      width: 42px;
    }
  }

  .navigation__link__title {
    @include font-size(12);

    margin-top: 0;

    @media only screen and (min-width: $screen-lg-min) {
      margin-top: 10px;
    }
  }
}

.navigation__menu-burger__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 42px; //58px;
  width: 42px; //58px;
  position: relative;
  z-index: 6;
  transition: background-color 0.3s ease-in-out;

  @media (min-width: $screen-lg-min) {
    display: none;
  }
}

.navigation__menu-burger {
  width: 26px;
  height: 17px;
  position: relative;
  transition: height 0.2s ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    border-radius: 7px;
    background-color: #000;
  }

  &:before {
    top: -3px;
    transition: transform 0.3s, top 0.3s 0.3s, background-color 0.3s ease-in-out;
  }

  &:after {
    bottom: -2px;
    transition: transform 0.3s, bottom 0.3s 0.3s, background-color 0.3s ease-in-out;
  }

  &--active {
    height: 19px;
    .navigation__menu-burger-line {
      opacity: 0;
    }

    &:before {
      top: 6px;
      transform: rotate(45deg);
      transition: transform 0.3s 0.3s, top 0.3s;
    }

    &:after {
      bottom: 7px;
      transform: rotate(-45deg);
      transition: transform 0.3s 0.3s, bottom 0.3s;
    }
  }
}

.navigation__menu-burger .navigation__menu-burger-line {
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #000;
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s ease-in-out;
}

// Create link item width based on number of items
@for $i from 1 through 10 {
  $menu-items: $i;

  // Dynamic Variables
  $width: math.div(100, $menu-items) * 1%;

  .navigation__items-group--#{$i} {
    .navigation__link {
      width: 33.3%;

      @media only screen and (min-width: $screen-lg-min) {
        width: $width;
      }
    }
  }
}
