.authModal__container {
  font-family: $font__avenir;
  z-index: 9000;
  margin: 0 auto;
  position: relative;
}

.authModal {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 25px 0 25px 0;
  color: #fff;
  width: calc(100vw - 100px);
  position: absolute;
  padding: 25px;
  margin: 0 auto;
  top: 100px;

  @media only screen and (max-width: $screen-md-min) and (orientation: portrait) {
    width: calc(100vw - 20px);
  }

  @media (min-width: $screen-sm-min) {
    max-width: 500px;
    top: 170px;
    right: 20px;
  }
}

.authModal__error-message {
  color: $color_feature;
  font-weight: bold;
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.authModal__center {
  text-align: center;
}

.authModal__row {
  display: flex;
}

.authModal__loginForm {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.authModal__closeIcon {
  position: absolute;
  @include font-size(28);
  width: 55px;
  height: 55px;
  margin: -25px 0 0 0;
  top: 0;
  right: 0;
  background-color: $color_links;
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);
  cursor: pointer;

  svg {
    width: 50%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (min-width: $screen-md-min) {
    margin: -25px -25px 0 0;
  }
}

.authModal__header {
  font-family: $font__lubalinBold;
  margin-bottom: 10px;
  @include font-size(20);
  text-transform: uppercase;

  @media (min-width: $screen-md-min) {
    @include font-size(32);
  }
}

.authModal__challenges-appected {
  display: flex;
  align-items: center;
  font-family: $font__avenir--bold;

  .text {
    @include font-size(16);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(18);
    }
  }

  .count {
    color: $color_feature;
    margin-left: 15px;
    @include font-size(26);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(36);
    }
  }
}

.authModal_input {
  margin: 0 0 10px;
  border: 0;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px 30px;
  color: white;
  border-radius: 25px 0 25px 0;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
    @include font-size(11);
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
    @include font-size(11);
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    @include font-size(11);
    color: white;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    @include font-size(11);
    color: white;
  }
}

.authModal__button {
  font-family: $font__lubalinBold;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  background-color: $color_links;
  display: inline-block;
  cursor: pointer;
  border: none;
  min-width: 160px;
  margin: 0 auto 10px;
  padding: 10px 10px;
  border-radius: 25px 0 25px 0;
  align-items: center;
  @include font-size(20);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }

  svg {
    position: absolute;
    height: 50px;
    width: 50px;
    left: -12px;
    top: -4px;

    @media only screen and (min-width: $screen-md-min) {
      height: 60px;
      width: 60px;
      position: absolute;
      left: -20px;
      top: -10px;
    }
  }
}

.authModal__button_download {
  padding-left: 43px;
  @include font-size(19);

  .icon {
    border: #fff 4px solid;
    border-radius: 50%;
    background-color: #ec0080;
    padding: 10px;

    height: 60px;
    width: 60px;
    position: absolute;
    left: -20px;
    top: -10px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: static;
      width: 100%;
      height: auto;

      .cls-2 {
        fill: #ffffff;
      }
    }
  }
}

.authModal__button_welcome {
  margin: 30px auto;
  padding-left: 50px;
  display: block;
}

.authModal__card {
  position: relative;
  padding: 15px 15px 0 15px;
  border-radius: 25px;

  .membership-card {
    width: 100%;
    height: auto;
  }
}

.authModal__card__buttons {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 575px) {
    flex-direction: column;

    .authModal__button {
      margin: 10px auto;
    }
  }
}

.authModal__card--preloader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.authModal__logo {
  position: absolute;
  left: 30px;
  margin-left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.authModal__card_header {
  font-family: $font__lubalinBold;
  text-transform: uppercase;
  @include font-size(24);
  span {
    @include font-size(38);
    display: block;
  }
}
.authModal__card_name {
  margin-top: 30px;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  @include font-size(24);
}

.authModal__card_footer {
  margin-top: 10px;
  display: flex;
  height: 100px;
}

.authModal__card_pin {
  margin-top: 20px;
  color: rgba(0, 0, 0, 1);
  span {
    display: block;
    font-family: $font__lubalinBold;
    @include font-size(38);
  }
}
.authModal__card_download {
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 20%;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
}
.authModal__register-prompt {
  @media only screen and (max-width: $screen-sm-min) {
    flex-direction: column;

    > div {
      margin: 10px 0 5px;
      width: 100%;
    }

    .authModal_input {
      width: 100%;
    }

    .authModal_input__count {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.authModal__button--logout {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  max-width: 200px;
}

// Avatar Modal
.authModal--avatar {
  .authModal__button {
    margin: 0 auto;
    display: block;
  }
  .authModal__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      flex-basis: 25%;
      text-align: center;
      margin-bottom: 10px;

      img {
        border: #fff 4px solid;
        border-radius: 50%;
        width: 70px;

        &.active {
          border-color: #fabc2c;
        }
      }
    }
  }
}

.authModal--account {
  text-align: center;

  .authModal__center,
  .authModal__button_download {
    margin-bottom: 20px;
  }
}
