// Pluse

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes show-tap-pulse {
  0% {
    transform: scale(0.2);
  }

  50% {
    transform: scale(0.24);
  }

  100% {
    transform: scale(0.2);
  }
}

@keyframes read-more-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
