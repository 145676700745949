.mappage__container {
  background: rgb(250, 188, 44);
  background: -moz-linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  background: linear-gradient(180deg, rgba(250, 188, 44, 1) 0%, rgba(254, 235, 52, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fabc2c",endColorstr="#feeb34",GradientType=1);

  padding: 15px 0;

  h1 {
    color: #000000;
    margin: {
      top: 0;
      right: 0;
      bottom: 10px;
      left: 0;
    }
    padding: 0;
  }
}

.mappage__featured-items {
  display: flex;
  flex-wrap: wrap;
}

.mappage__manifesto,
.mappage__challenges {
  flex-basis: 100%;
  margin-bottom: 20px;
  min-width: 0;

  border-radius: 25px 0;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: calc(50% - 10px);

    &:nth-child(2n + 0) {
      margin-left: auto;
    }
  }
}

.mappage__manifesto {
  .manifesto__container,
  .container-fluid {
    height: 100%;
    padding: 0;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    left: 15px;
  }
}
