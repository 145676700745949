.grid__more-button {
  outline: 0;
  cursor: pointer;
  border: none;
  background-color: $color_links;
  color: #000000;
  @include font-size(16);
  padding: 10px 50px;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  @media (min-width: $screen-md-min) {
    padding: 18px 80px;
    @include font-size(24);
  }

  @media only screen and (min-width: $screen-md-min) {
    &:hover {
      color: white;
      background-color: #000000;
    }
  }
}
