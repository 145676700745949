.challenge-dropdown__container {
  cursor: pointer;
  position: relative;
  width: 250px;

  @media only screen and (min-width: $screen-md-min) {
    width: auto;
  }

  &--open {
    .challenge-dropdown__arrow {
      transform: rotate(180deg);
    }
    .challenge-dropdown__list {
      display: block;
    }
  }

  &--close {
    .challenge-dropdown__list {
      display: none;
    }
  }
}

.challenge-dropdown__header {
  background-color: #fff;
  color: $color_feature;
  padding: {
    top: 10px;
    bottom: 10px;
    left: 30px;
  }
  @include font-size(22);
  text-transform: uppercase;
  border-radius: 25px 0 25px 0;
  position: relative;
  z-index: 201;
  display: flex;
  align-items: center;

  .challenge-dropdown__arrow {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: 20px;

    display: flex;
    align-items: center;
    transition: 0.3s transform ease-in-out;
  }
}

.challenge-dropdown__list {
  position: absolute;
  width: 100%;
  z-index: 200;
  border-radius: 0 0 25px 0;
  overflow: hidden;
  margin-top: -25px;

  font-family: $font__avenir;
}

.challenge-dropdown__list {
  font-family: $font__lubalinBold;
  &--survey {
    font-family: $font__avenir;
  }

  span {
    background-color: $color_feature;
    color: #fff;
    display: block;
    width: 100%;
    padding: {
      top: 7.5px;
      bottom: 7.5px;
      left: 30px;
    }
    @include font-size(22);
    text-transform: uppercase;

    &:first-child {
      padding-top: 35px;
    }

    &:hover {
      background-color: #fff;
      color: $color_feature;
    }
  }
}

// Challenge Dropdown -- Can't Do dropdown modifier
.challenge-dropdown--cant-do {
  .challenge-dropdown__container {
    width: 100%;
  }
  .challenge-dropdown__header {
    @include font-size(18);
    text-align: left;
    padding: 10px 15px;
    border-radius: 0;

    justify-content: center;
  }

  .challenge-dropdown__arrow {
    margin-right: 0;
    margin-left: 15px;
  }

  .challenge-dropdown__list {
    margin-top: 0;

    border-radius: 0 0 25px 0;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);

    .title {
      color: #000;
      text-align: center;
      font-weight: bold;

      @include font-size(20);
      margin-bottom: 15px;
    }

    .description {
      color: #000;
      @include font-size(16);
      text-align: center;
    }

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        width: 50px;
        height: 46px;
        display: flex;
        align-items: flex-end;
      }

      &.selected {
        svg {
          width: 100%;
        }
      }

      svg {
        width: 41px;
      }

      .label {
        @include font-size(16);
        color: #000;
        margin-left: 30px;
      }
    }

    .reason-input {
      display: none;
    }
  }

  .challenge-dropdown__submit {
    text-align: center;
    margin-top: 20px;

    button {
      cursor: pointer;
      text-transform: uppercase;
      border-radius: 25px 0 25px 0;
      background-color: none;
      border: none;
      padding: 15px 25px;
      color: #fff;

      font-weight: bold;
      @include font-size(22);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);

      @media only screen and (min-width: $screen-md-min) {
        padding: 10px 35px;
      }
    }
  }

  // type modifiers
  &.food {
    // $food_type;

    .challenge-dropdown__submit button {
      background-color: $food_type;
    }

    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $food_type;
    }

    .challenge-dropdown__arrow svg .cls-1 {
      fill: $food_type;
    }
    .challenge-box-unticked {
      .cls-2 {
        stroke: $food_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $food_type;
      }
      .cls-3 {
        stroke: $food_type;
      }
    }
  }
  &.creative {
    // $creative_type;

    .challenge-dropdown__submit button {
      background-color: $creative_type;
    }

    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $creative_type;
    }

    .challenge-dropdown__arrow svg .cls-1 {
      fill: $creative_type;
    }
    .challenge-box-unticked {
      .cls-2 {
        stroke: $creative_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $creative_type;
      }
      .cls-3 {
        stroke: $creative_type;
      }
    }
  }
  &.recycle {
    // $recycle_type;

    .challenge-dropdown__submit button {
      background-color: $recycle_type;
    }

    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $recycle_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $recycle_type;
    }

    .challenge-box-unticked {
      .cls-2 {
        stroke: $recycle_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $recycle_type;
      }
      .cls-3 {
        stroke: $recycle_type;
      }
    }
  }
  &.fauna {
    // $fauna_type;

    .challenge-dropdown__submit button {
      background-color: $fauna_type;
    }
    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $fauna_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $fauna_type;
    }

    .challenge-box-unticked {
      .cls-2 {
        stroke: $fauna_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $fauna_type;
      }
      .cls-3 {
        stroke: $fauna_type;
      }
    }
  }
  &.water {
    // $water_type;

    .challenge-dropdown__submit button {
      background-color: $water_type;
    }
    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $water_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $water_type;
    }

    .challenge-box-unticked {
      .cls-2 {
        stroke: $water_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $water_type;
      }
      .cls-3 {
        stroke: $water_type;
      }
    }
  }
  &.energy {
    // $energy_type;

    .challenge-dropdown__submit button {
      background-color: $energy_type;
    }
    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $energy_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $energy_type;
    }
    .challenge-box-unticked {
      .cls-2 {
        stroke: $energy_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $energy_type;
      }
      .cls-3 {
        stroke: $energy_type;
      }
    }
  }
  &.flora {
    // $flora_type;

    .challenge-dropdown__submit button {
      background-color: $flora_type;
    }
    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $flora_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $flora_type;
    }
    .challenge-box-unticked {
      .cls-2 {
        stroke: $flora_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $flora_type;
      }
      .cls-3 {
        stroke: $flora_type;
      }
    }
  }
  &.travel {
    // $travel_type;

    .challenge-dropdown__submit button {
      background-color: $travel_type;
    }
    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $travel_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $travel_type;
    }
    .challenge-box-unticked {
      .cls-2 {
        stroke: $travel_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $travel_type;
      }
      .cls-3 {
        stroke: $travel_type;
      }
    }
  }

  &.schools {
    // $school_type;

    .challenge-dropdown__submit button {
      background-color: $schools_type;
    }
    .challenge-dropdown__title,
    .challenge-dropdown__list span {
      color: $schools_type;
    }
    .challenge-dropdown__arrow svg .cls-1 {
      fill: $schools_type;
    }
    .challenge-box-unticked {
      .cls-2 {
        stroke: $schools_type;
      }
    }

    .challenge-box-ticked {
      .cls-4 {
        fill: $schools_type;
      }
      .cls-3 {
        stroke: $schools_type;
      }
    }
  }

  .challenge-dropdown__container {
    &--open .challenge-dropdown__header {
      border-radius: 0;
    }

    &--close .challenge-dropdown__header {
      border-radius: 0 0 25px 0;
    }
  }
}
