.details-container {
  margin-bottom: 20px;
}

.details-container__description {
  text-align: left;
  margin-bottom: 10px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 40px;
  }
}

.details-container__description__title {
  @include font-size(32);

  @media (min-width: $screen-md-min) {
    padding: 20px 0 0 0;
  }
}

.details-container__description__content {
  font-family: $font__lubalinBold;

  .copy-inner {
    overflow: hidden;
    height: auto;
    max-height: 55px;

    @media (min-width: $screen-md-min) {
      max-height: 60px;
    }

    h3 {
      padding: 20px 0;
    }

    p {
      font-family: $font__avenir;
      padding: 0 0 20px 0;

      @media (min-width: $screen-md-min) {
        @include font-size(18);
      }
    }

    &.reveal {
      max-height: none;
    }
  }
}

.details-container__showmore button {
  outline: 0;
  color: #000;
  cursor: pointer;
  background-color: $color_links;
  border: none;
  padding: 10px 20px;
  margin: 20px 3px 3px;
  border-radius: 45px;
  @include font-size(16);
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.35);

  @media (min-width: $screen-md-min) {
    padding: 15px 30px;
    @include font-size(24);
  }
}

.details-container__screenshots {
  text-align: left;
  margin-bottom: 10px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 50px;
  }

  h2 {
    @include font-size(32);
  }
}
